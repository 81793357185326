export const USER_GROUPS_LIST = ['ORM', 'Bloodhound'];
export const RETAILER_LIST_FOR_PEGGY = ['amazon', 'b%26n', 'apple', 'google', 'kobo']; //%26 means "&" (for query string)
export const AUDIO_CONTRACT_STATUS = ['Valid', 'In-Renewal', 'Expired'];
export const HEADER = {
  H_MOBILE: 64,
  H_DESKTOP: 58,
  H_DESKTOP_OFFSET: 58,
};

export const NAV = {
  W_VERTICAL: 280,
  W_MINI: 88,
};
export const CAMPAIGN_TYPE_ID = {
  'Bookbub - Featured Deals': '5d39a42a92d1230011075637',
  'BookBub Tail': '604a5d7f8503150013f7191f',
};

export const COMPARISON_OPERATOR = {
  $lte: '<=',
  $lt: '<',
  $gte: '>=',
  $gt: '>',
  $eq: '=',
};

export const DRAFT_PARENT = {
  PersonalDraft: 1,
  SharedDraft: 2,
};

export const SHARED_DRAFT_MODAL_MODE = {
  ADD: 1,
  EDIT: 2,
  ADD_TITLE: 3,
};

export const VALID_DATE_FORMATS = [
  'M/D/YYYY',
  'M/DD/YYYY',
  'MM/D/YYYY',
  'MM/DD/YYYY',
  'M-D-YYYY',
  'M-DD-YYYY',
  'MM-D-YYYY',
  'MM-DD-YYYY',
  'M/D/YY',
  'M/DD/YY',
  'MM/D/YY',
  'MM/DD/YY',
  'M-D-YY',
  'M-DD-YY',
  'MM-D-YY',
  'MM-DD-YY',
];
