import { getGridNumericOperators } from '@mui/x-data-grid-pro';
import InputNumberInterval from './InputNumberInterval';

export const getNumericOperators = () => {
  return [
    ...getGridNumericOperators(),
    {
      label: 'Between',
      value: 'between',
      getApplyFilterFn: filterItem => {
        if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
          return null;
        }
        if (filterItem.value[0] == null || filterItem.value[1] == null) {
          return null;
        }

        return ({ value }) => {
          return value !== null && filterItem.value[0] <= value && value <= filterItem.value[1];
        };
      },
      InputComponent: InputNumberInterval,
    },
  ];
};
