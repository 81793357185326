import { Box, Grid, Typography } from '@mui/material';

function Heading({ children, heading }) {
  return (
    <Box>
      <Grid container display='flex' justifyContent='space-between'>
        <Grid item>
          <Typography variant='h4'>{heading}</Typography>
        </Grid>
        {children && <Grid item>{children}</Grid>}
      </Grid>
      <hr />
    </Box>
  );
}

export default Heading;
