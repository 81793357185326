import React, { useState, useEffect } from 'react';
import APIService from './utils/APIService';
import { getCookies } from './utils/Helper';

const UserContext = React.createContext();

const UserContextProvider = props => {
  const [token, setToken] = useState(getCookies(process.env.REACT_APP_USERTOKEN_COOKIE_NAME));
  const [user, setUser] = useState(null);
  const [allowBHFrontlist, setAllowBHFrontlist] = useState(true);
  useEffect(() => {
    if (!token) return;
    const fetchUserInfo = async () => {
      if (token) {
        let userRes = await APIService.get(`/user/detail?token=${token}`);
        console.log('userRes ', userRes)
        setUser(userRes.data);
      }
    };
    fetchUserInfo();
  }, [token]);

  useEffect(() => {
    if (!user) return;
    let email = user.email;
    let domain = email?.split('@')[1];
    if (domain === 'openroadmedia.com' || domain === 'sculptsoft.com') {
      //If user email has "openroadmedia or sculptsoft" domain, don't allow them to access Bloodhound frontlist titles unless their role is "marketing admin"
      if (user.role && user.role === 'marketing admin') {
        setAllowBHFrontlist(true);
      } else {
        setAllowBHFrontlist(false);
      }
    } else {
      setAllowBHFrontlist(true);
    }
  }, [user]);

  /* 
		If user logoff or login, update token from child component
	*/
  const refreshToken = newToken => {
    setToken(newToken);
  };

  return (
    <UserContext.Provider value={{ user, token, refreshToken, allowBHFrontlist }}>
      {props.children}
    </UserContext.Provider>
  );
};
export { UserContextProvider, UserContext };
