import { GridFilterInputValue } from '@mui/x-data-grid-pro';

// Custom filter operator that excludes rows where the cell value contains the filter value.
export const doesNotContainOperator = {
  label: 'does not contain',
  value: 'doesNotContain',
  getApplyFilterFn: filterItem => {
    // Check if filterItem, its value, or field is missing
    if (!filterItem || !filterItem.value || !filterItem.field) {
      return null;
    }

    return value => {
      console.log('value', value);
      // Ensure the value is a string before calling toLowerCase
      if (typeof value !== 'string') {
        return true;
      }
      // Perform the filtering by checking if the value does not contain the filter string
      return !value.toLowerCase().includes(filterItem.value.toLowerCase());
    };
  },
  InputComponent: GridFilterInputValue,
};
