/* 
	This constant declare book cover size as different part of application. Keeping this as constant as this sizes will be cached in cloudfront so going forward, if we are designing new template, we should adhere to pre-existing size to reduce cost

	This file is copied from ORION-FRONTEND and try to use same constant at various places for Peggy
	BC = Book Cover

*/
export default Object.freeze({
  BC_SEARCHRESULT: { width: 120, height: 180 },
  BC_LISTICLE: { width: 210, height: 320 },
  BC_DEALSPAGE: { width: 180, height: 0 },
  BC_CATALOGBOOKCARD: { width: 300, height: 0 },
  BC_BOOKDETAIL: { width: 300, height: 0 },
  BC_THUMBNAIL: { width: 50, height: 0 },
});
