import { Stack } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import APIService from 'src/utils/APIService';
import { TRIGGER_TITLE_RESULT_CHILD_COLUMNS } from 'src/constants/dataGridColumns';
import ORMDataGridV2 from 'src/components/Shared/Table/ORMDataGridV2';

const TriggerTitlesChildTable = ({ id }) => {
  const [state, setState] = useState({
    error: false,
    data: [],
    isLoading: true,
  });

  const fetchData = useCallback(async () => {
    const response = await APIService.get(`/triggertitle/result/${id}`);
    const { status, data } = response || {};
    if (status === 200) {
      setState({
        data: data.triggerTitles,
        error: false,
        isLoading: false,
      });
    } else {
      setState({
        data: [],
        error: true,
        isLoading: false,
      });
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const { error, data, isLoading } = state;

  if (isLoading) {
    return 'Loading.........';
  }
  if (data?.length === 0) {
    return 'No Data Found';
  }
  if (error) {
    return 'Something went wrong!';
  }
  return (
    <Stack sx={{ mx: 2, py: 2, height: '100%', boxSizing: 'border-box' }}>
      <ORMDataGridV2
        isDefaultCompact={true}
        columns={TRIGGER_TITLE_RESULT_CHILD_COLUMNS}
        rows={data}
        toolbarOptions={['SEARCH']}
        uniqueRowId='primary_isbn'
        variant='secondary'
        height='auto'
        pagination
      />
    </Stack>
  );
};

export default React.memo(TriggerTitlesChildTable);
