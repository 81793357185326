import axios from 'axios';
import { getCookies } from './Helper';
import ormToast, { TOAST_TYPE } from './ormToast';

class Service {
  constructor() {
    this.service = axios.create({
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
      timeout: 0,
    });

    // Apply token if it exists
    const token = getCookies('ormuser');
    if (token) {
      this.service.defaults.headers.common['x-auth-token'] = token;
    }

    // Request interceptor to modify the baseURL dynamically
    this.service.interceptors.request.use(config => {
      // Check the request method
      console.log('CONFIG METHOD ', config.method)
      //|| process.env.REACT_APP_ORION_ENV === 'development'
      if (config.method === 'get'  ) {
        config.baseURL = process.env.REACT_APP_API_URL; // For GET requests
      } else {
        config.baseURL = process.env.REACT_APP_NONCLOUDFRONT_URL; // For all other requests
      }

      return config;
    }, error => {
      return Promise.reject(error);
    });
  }

  async retryRequest(requestFunction, path, payload, headers) {
    let retries = 3;

    while (retries > 0) {
      try {
        const response = await requestFunction(path, payload, headers);
        return response;
      } catch (error) {
        if (error.response && error.response.status >= 500) {
          retries--;

          if (retries === 0) {
            return this.handleErrorResponse(error);
          } else {
            await new Promise(resolve => setTimeout(resolve, 1000)); // Delay before retry
          }
        } else {
          return error.response || this.handleErrorResponse(error);
        }
      }
    }
  }

  async get(path, config) {
    return this.retryRequest(this.service.get.bind(this.service), path, config);
  }

  async patch(path, payload) {
    return this.retryRequest(this.service.patch.bind(this.service), path, payload);
  }

  async post(path, payload, headers = { 'content-type': 'application/json' }) {
    return this.retryRequest(this.service.post.bind(this.service), path, payload, { headers });
  }

  async put(path, payload, headers = { 'content-type': 'application/json' }) {
    return this.retryRequest(this.service.put.bind(this.service), path, payload, { headers });
  }

  async delete(path, payload) {
    return this.retryRequest(this.service.delete.bind(this.service), path, { data: payload });
  }

  handleErrorResponse(error) {
    if (error && error.response) {
      const { status, data } = error.response;
      console.error('Error response:', { status, data });
      return error.response;
    } else {
      ormToast('Something went wrong!', TOAST_TYPE.ERROR);
      return { status: 500, data: { message: 'Something went wrong!' } };
    }
  }

  async eventSourcePost(path, payload, headers = { 'Content-Type': 'application/json' }) {
    const url = `${process.env.REACT_APP_API_URL}${path}`;
    const token = getCookies('ormuser');
    const eventSource = new EventSource(url, {
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token,
        ...headers,
      },
    });
    console.log('eventSource ', eventSource);
    return eventSource;
  }
}

export default new Service();
