import React from 'react';
import { Box, TextField } from '@mui/material';
import { useGridRootProps } from '@mui/x-data-grid-pro';
import { Sync } from '@mui/icons-material';

export default function InputNumberInterval(props) {
  const rootProps = useGridRootProps();
  const { item, applyValue, focusElementRef = null } = props;

  const filterTimeout = React.useRef();
  const [filterValueState, setFilterValueState] = React.useState(item.value ?? '');
  const [applying, setIsApplying] = React.useState(false);

  React.useEffect(() => {
    return () => {
      clearTimeout(filterTimeout.current);
    };
  }, []);

  React.useEffect(() => {
    const itemValue = item.value ?? [undefined, undefined];
    setFilterValueState(itemValue);
  }, [item.value]);

  const updateFilterValue = (lowerBound, upperBound) => {
    clearTimeout(filterTimeout.current);
    setFilterValueState([lowerBound, upperBound]);

    setIsApplying(true);
    filterTimeout.current = setTimeout(() => {
      setIsApplying(false);
      applyValue({ ...item, value: [lowerBound, upperBound] });
    }, rootProps.filterDebounceMs);
  };

  const handleUpperFilterChange = event => {
    const newUpperBound = event.target.value;
    updateFilterValue(filterValueState[0], newUpperBound);
  };
  const handleLowerFilterChange = event => {
    const newLowerBound = event.target.value;
    updateFilterValue(newLowerBound, filterValueState[1]);
  };

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'end',
        height: 48,
        pl: '20px',
      }}
    >
      <TextField
        name='lower-bound-input'
        placeholder='From'
        label='From'
        variant='standard'
        value={Number(filterValueState[0])}
        onChange={handleLowerFilterChange}
        type='number'
        inputRef={focusElementRef}
        sx={{ mr: 2 }}
      />
      <TextField
        name='upper-bound-input'
        placeholder='To'
        label='To'
        variant='standard'
        value={Number(filterValueState[1])}
        onChange={handleUpperFilterChange}
        type='number'
        InputProps={applying ? { endAdornment: <Sync /> } : {}}
      />
    </Box>
  );
}
