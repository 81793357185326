import React from 'react';
import Cookies from 'universal-cookie';
import { Link } from 'react-router-dom';
// import sanitizeHTML from 'sanitize-html'; // use this or change to react-render-html
import slugify from 'slugify';
import { nanoid } from 'nanoid';
import axios from 'axios';
import Papa from 'papaparse';
import {
  CalculateBroadCastYearOfEvent,
  CalculateMonthDayEvent,
  FormatDateString,
  isValidDateFormat,
  _formatDate,
  FormatDateTime,
} from './DateTimeHelper';
import ormToast, { TOAST_TYPE } from './ormToast';
import { ACTIVATION_BUSINESS_UNIT } from 'src/constants/ActivationConstants';

const cookies = new Cookies();

/* 
  To slugify book title to - separated string to build book detail link for catalog site.
  Used from bookedit invalidatecache
*/
export const formatSlug = title => {
  if (title) {
    return slugify(title, {
      lower: true,
      strict: true,
      remove: /[*+~.()'"!:@]/g,
    });
  }
};

//Getting unique comma separated customcategory ids and name
export const getCommaSeparatedValue = (obj, parentPropertyName, childPropertyName) => {
  let data = new Set();

  obj[parentPropertyName] &&
    obj[parentPropertyName].forEach(item => {
      item.forEach(child => {
        data.add(child[childPropertyName]);
      });
    });
  return Array.from(data);
};

/* 
  At various tables, we display author name, this function will accept author firebrand id and author name and return <Link> to author's social data like http://local.peggy.openroadmedia.com/social-data?id=46410376&name=Arthur%20C.%20Clarke

  Currently it is used in TSD Report blastRecommendation result
*/
export const generateAuthorSocialDataLink = (authorFirebrandId, authorName) => {
  if (authorFirebrandId && authorName)
    return (
      <Link
        to={{ pathname: '/others/social-data', search: `?id=${authorFirebrandId}&name=${authorName}` }}
        target={'_blank'}
      >
        {authorName}
      </Link>
    );
};
// export const apppendFilters = (colsData, clear) => {
//   colsData.forEach(item => {
//     item.filter = customFilter({
//       onFilter: customFilterColumn,
//     });
//     item.filterRenderer = (onFilter, column) => (
//       <ColumnFilter type={item.type} clear={clear} onFilter={onFilter} column={column} />
//     );
//   });
//   return colsData;
// };
/* 
  Currently it is used in HaloResults, TwoFiftyKSaleTableColumns,ContentNlDealsSaleTableColumns, ViewServiceRequest
*/
export const generateBookDetailLink = (isbn, title) => {
  if (isbn && title)
    return (
      <Link to={{ pathname: `/book/${isbn}` }} target={'_blank'}>
        {title}
      </Link>
    );
};

/* 
  Currently it is used in ViewServiceRequest
*/
export const generateCampaignLinkNew = (campaignId, title) => {
  if (campaignId && title)
    return (
      <Link to={{ pathname: `/campaign/edit/${campaignId}` }} target={'_blank'}>
        {title}
      </Link>
    );
  else return '';
};

//To generate Unique Key for any given component
export const generateUniqueComponentKey = (size = 16) => {
  return nanoid(size);
  //crypto package was too expensive, to reduce bundle size replace it with uuid
  //return crypto.randomBytes(16).toString("hex");
};

export const setCookies = (name, value, expiryAfterMinutes = 86400) => {
  let expire = new Date();
  //Application somehow unable to set cookie so storing token in sessionStorage and reading from same
  //sessionStorage.setItem(name,value)
  expire.setDate(expire.getDate() + expiryAfterMinutes);
  try {
    if (name === 'ormuser' && process.env.REACT_APP_ORION_ENV !== 'development')
      cookies.set(name, value, { expires: expire, path: '/', secure: true });
    else cookies.set(name, value, { expires: expire, path: '/' });
  } catch (err) {
    console.log(`Error setting cookie ${name} - value is ${value} --> ${err}`);
  }
};

export const getCookies = name => cookies.get(name); //|| sessionStorage.getItem(name)
export const removeCookies = (name, options = { path: '/' }) => cookies.remove(name, options);

/*
 * 15 - ORM - Murder & Mayhem NL
 * 18 - ORM - Archive NL
 * 20 - ORM - Portalist NL
 * 22 - ORM - The Lineup NL
 * 24 - ORM - The Reader NL
 * 36 - ORM - A Love So True NL
 *
 * 43 - ORM - A Love So True Content NL
 * 44 - ORM - Archive Content NL
 * 45 - ORM - Murder & Mayhem Content NL
 * 46 - ORM - Portalist Content NL
 * 47 - ORM - The Lineup Content NL
 */
export const isNewsLetterCampaign = value => {
  const nlCampaigns = new Set([15, 18, 20, 22, 24, 36, 43, 44, 45, 46, 47]);
  return nlCampaigns.has(Number(value));
};

/*
 * 37 - ORM - Early Bird Books Site Deals
 * 38 - ORM - A Love So True Site Deals
 * 39 - ORM - Archive Site Deals
 * 40 - ORM - Murder & Mayhem Site Deals
 * 41 - ORM - Portalist Site Deals
 * 42 - ORM - The Lineup Site Deals
 * 54 - ORM - Early Bird Books 250 Sale
 * 13 - ORM - Early Bird Books NL
 */
export const isSiteDealsCampaign = value => {
  const sdCampaigns = new Set([37, 38, 39, 40, 41, 42, 54, 13]);
  return sdCampaigns.has(Number(value));
};

/*
 * 25 - Nook Deals
 * 26 - iBooks Deals
 * 27 - Google Play Deals
 * 28 - Kobo Deals
 * 60 - Amazon - Deals
 */
export const isRetailPromotionCampaign = value => {
  const rtPCampaigns = new Set([25, 26, 27, 28, 60]);
  return rtPCampaigns.has(Number(value));
};

export const generateURL = (siteName, slug) => {
  let environment = `${process.env.REACT_APP_ORION_ENV}`;
  let protocol = 'https';
  if (environment === 'local') {
    protocol = 'http';
    environment = 'local.';
  } else if (environment === 'staging') {
    environment = 'staging.';
  }
  return `${protocol}://${environment}${siteName}/${slug}`;
};

export const generateCampaignLink = (_id, campaignRoute) => {
  if (typeof window !== 'undefined') {
    const hostname = window.location.hostname;
    let environment = '';
    let protocol = 'https';
    if (hostname.includes('local')) {
      environment = 'local.';
      protocol = 'http';
    } else if (hostname.includes('staging')) {
      environment = 'staging.';
    }

    switch (campaignRoute) {
      case 'freebook':
        return `${protocol}://${environment}earlybirdbooks.com/${campaignRoute}/index.html?q=${_id}`;
      case 'giveaway':
        return [
          `${protocol}://${environment}earlybirdbooks.com/${campaignRoute}?q=${_id}`,
          `${protocol}://${environment}the-line-up.com/${campaignRoute}?q=${_id}`,
          `${protocol}://${environment}theportalist.com/${campaignRoute}?q=${_id}`,
          `${protocol}://${environment}explorethearchive.com/${campaignRoute}?q=${_id}`,
          `${protocol}://${environment}alovesotrue.com/${campaignRoute}?q=${_id}`,
          `${protocol}://${environment}murder-mayhem.com/${campaignRoute}?q=${_id}`,
        ];
      default:
        return `${protocol}://${environment}earlybirdbooks.com/${campaignRoute}?q=${_id}`;
    }
  }
};

/**
 * title: title of the book
 * primaryisbn: isbn of the book
 * asin: asin of the book
 * googleId: google id of the book
 * appleId: apple id of the book
 * appleEan: apple ean of the book
 * retailerSiteLinks: links coming from DB, if any
 * amazon: amazon affiliate tag coming from sitesettings
 * apple: apple affiliate tag coming from sitesettings
 * barnes_nobles: b&n affiliate tag coming from sitesettings
 * kobo: kobo affiliate tag coming from sitesettings
 * takes retailer affiliate and returns a array of objects with retailer affiliates links
 */

// we moved this functionality into rest api
// export const generateAffiliateLinks = (book, affiliate) => {
//   const { amazon, apple, barnes_nobles, kobo } = affiliate;
//   const primaryisbn = book.primary_isbn ? book.primary_isbn : book.primaryisbn;
//   const googleId = book.google_id ? book.google_id : book.googleId;
//   const appleEan = book.apple_ean ? book.apple_ean : book.appleEan;
//   const appleId = book.apple_id ? book.apple_id : book.appleId;
//   const overdriveId = book.overdriveid ? book.overdriveid : book.overdriveId;
//   const retailerSiteLinks = book.retailer_site_links ? book.retailer_site_links : book.retailerSiteLinks;
//   const asin = book.asin;
//   const Title = book.title ? book.title : book.Title;
//   const affiliates = [];
//   // Amazon affiliate links
//   let amazon_url = "";
//   if (asin) {
//     amazon_url = `https://www.amazon.com/dp/${asin}`;
//   }
//   // E.L temp fix for compensating for marketing services that don't have ASIN's
//   if (!asin && retailerSiteLinks && retailerSiteLinks.length) {
//     const amazonLink = retailerSiteLinks.find(site => site.name === "Amazon");
//     if (amazonLink) amazon_url = amazonLink.url;
//   }

//   if (amazon && amazon_url) {
//     amazon_url += `?tag=${amazon}`;
//   }

//   if (amazon_url) {
//     affiliates.push({
//       name: "Amazon",
//       ref: "amazonLink",
//       url: amazon_url,
//     });
//   }

//   // Kobo and Barnes & Noble affiliate link
//   if (primaryisbn) {
//     let urlBarn = "";

//     // Barnes & Noble
//     if (barnes_nobles && primaryisbn) {
//       urlBarn = `http://www.anrdoezrs.net/links/${barnes_nobles}/type/dlg/https://www.barnesandnoble.com/w?ean=${primaryisbn}`;
//     }

//     affiliates.push({
//       name: "Barnes & Noble",
//       ref: "bnLink",
//       url: urlBarn,
//     });

//     // iTunes affiliate link
//     let apple_url = "";

//     // RT: Assign apple ean13 or primaryisbn
//     let title = "";
//     if (Title){
//       title = Title.toLowerCase();
//       title = title.split(' ');
//       title = title.join('-');
//       title = unescape(title);
//     }
//     if (appleId && title) {
//       apple_url = `https://books.apple.com/us/book/${title}/id${appleId}`;
//     } else if (appleEan) {
//       apple_url = `http://itunes.apple.com/us/book/isbn${appleEan}`;
//     } else {
//       apple_url = `http://itunes.apple.com/us/book/isbn${primaryisbn}`;
//     }

//     // RT: append affliate code if we have it
//     if (apple) {
//       apple_url += `?ct=${apple}&at=10lrBN`;
//     } else {
//       apple_url += "?at=10lrBN";
//     }

//     affiliates.push({
//       name: "Apple",
//       ref: "itunesLink",
//       url: apple_url,
//     });

//     // Google affiliate link
//     //if (googleId) {
//       affiliates.push({
//         name: "Google",
//         ref: "googleLink",
//         url: `https://play.google.com/store/books/details/?id=ISBN_${primaryisbn}&PAffiliateID=1101lHGm`, //E.L hardcoded affiliate id because it is the same regardless of site id's used
//       });
//     //}

//     if (kobo) {
//       affiliates.push({
//         name: "Kobo",
//         ref: "koboLink",
//         url: `https://click.linksynergy.com/deeplink?id=27lTva583xw&mid=37217&u1=${kobo}&murl=https://www.kobo.com/us/en/search?Query=${primaryisbn}`,
//       });
//     }

//     // Overdrive affiliate link
//     if (overdriveId) {
//       affiliates.push({
//         name: "Overdrive",
//         ref: "overdriveLink",
//         url: `https://www.overdrive.com/media/${overdriveId}/${title}`,
//       });
//     }

//     if (retailerSiteLinks && retailerSiteLinks.length > 0) {
//       const buyFromUs = retailerSiteLinks.find(link => link.name && link.name.toLowerCase() === "buy from us");
//       if(buyFromUs && buyFromUs.url) {
//         affiliates.push({
//           name: "Buy From US",
//           ref: "buyfromusLink",
//           url: buyFromUs.url
//         });
//       }
//     }

//     return affiliates;
//   }
// };

export const generateEmptyLinks = links => {
  const linkSet = new Set();
  // determining the links that are already present in the array
  for (let i = 0; i < links.length; i++) {
    switch (formatRetailerName(links[i].name)) {
      case `Amazon`:
        linkSet.add('Amazon');
        break;
      case `Apple`:
        linkSet.add('Apple');
        break;
      case `Apple Books`:
        linkSet.add('Apple');
        break;
      case `B&N`:
        linkSet.add('B&N');
        break;
      case `Barnes & Noble`:
        linkSet.add('B&N');
        break;
      case `Google`:
        linkSet.add('Google');
        break;
      case `Kobo`:
        linkSet.add('Kobo');
        break;
      case `Buy From Us`:
        linkSet.add('Buy From Us');
        break;
      default:
        linkSet.add(links[i].name);
        break;
    }
  }

  const pushLink = (links, link, shortName) => {
    links.push({
      name: link,
      ref: link.toLowerCase(),
      shortName,
      url: '',
    });
  };

  // pushing the links that are not in the array
  if (!linkSet.has('Amazon')) {
    pushLink(links, 'Amazon', 'amazon');
  }
  if (!linkSet.has('Apple')) {
    pushLink(links, 'Apple', 'apple');
  }
  if (!linkSet.has('B&N')) {
    pushLink(links, 'Barnes & Noble', 'barnesNoble');
  }
  if (!linkSet.has('Google')) {
    pushLink(links, 'Google', 'google');
  }
  if (!linkSet.has('Kobo')) {
    pushLink(links, 'Kobo', 'kobo');
  }
  if (!linkSet.has('Buy From US')) {
    pushLink(links, 'Buy From US', 'buyFromUs');
  }

  return links;
};

// use to format the name of retailer's buttons
export const formatRetailerName = retailer => {
  if (retailer) {
    switch (retailer) {
      case 'Amazon':
        return 'Amazon';
      case 'Apple':
        return 'Apple';
      case 'Apple Books':
        return 'Apple';
      case 'B&N':
        return 'B&N';
      case 'Barnes & Noble':
        return 'B&N';
      case 'Google':
        return 'Google';
      case 'Kobo':
        return 'Kobo';
      case 'Buy From US':
        return 'Buy From US';
      default:
        return retailer;
    }
  }
};

/**
 * links: Links to be rearranged
 * condition: where the function is called from -- based on certain page, we are ordering the links differently
 */
export const retailerReArrange = (links, condition) => {
  if (!links) return;
  for (let i = 0; i < links.length; i++) {
    switch (formatRetailerName(links[i].name)) {
      case 'Amazon':
        links[i].order = 1;
        break;
      case 'B&N':
        condition === 'bookedit' ? (links[i].order = 3) : (links[i].order = 2);
        break;
      case 'Apple':
        condition === 'bookedit' ? (links[i].order = 2) : (links[i].order = 3);
        break;
      case 'Google':
        links[i].order = 4;
        break;
      case 'Kobo':
        links[i].order = 5;
        break;
      case 'Overdrive':
        links[i].order = 6;
        break;
      case 'Buy From US':
        links[i].order = 7;
        break;
      default:
        links[i].order = 8;
    }
  }
  return links.sort((a, b) => {
    return a.order - b.order;
  });
};

//Shold receive books array which contains minimum ISBN field. It will append fetch googleId to incoming array and return same array to client
export const FetchGoogleIds = async books => {
  //Based on https://dev.to/tqbit/use-async-await-with-javascript-s-map-and-other-high-order-functions-25mn
  const FetchId = books.map(async book => {
    let url = `https://www.googleapis.com/books/v1/volumes?q=ISBN:${book.ISBN}&orderBy=newest&fields=items(id)&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
    let response = await axios.get(url);
    console.log('google response.data ', response.data);
    if (response && response.status === 200 && response.data && response.data.items && response.data.items.length > 0)
      book.Google_Id = response.data?.items[0]?.id;

    return book;
  });
  return await Promise.all(FetchId);
};

export const formatBookCoverImage = image => {
  if (!image) return null;
  let imagePath = `${process.env.REACT_APP_BOOK_COVER_S3_BASE_URL}/${image}`;
  return imagePath;
};

export const GetDefaultNotification = (startDate, endDate, notificationArr = []) => {
  let notificationOption = process.env.REACT_APP_NOTIFICATION_OPTION.split(',');
  const createNotification = (notificationArray, type) => {
    let date = startDate;
    if (type === 'end') {
      date = endDate;
    }
    if (notificationArray.length === 0) {
      return notificationOption.map(option => {
        option = parseInt(option);
        return {
          email: [],
          eventdate: CalculateMonthDayEvent(date, option),
          eventday: option,
          eventyear: CalculateBroadCastYearOfEvent(date, option),
          eventtype: type,
        };
      });
    } else {
      return notificationOption.map(option => {
        option = parseInt(option);
        let noti = notificationArray.find(n => n.eventday === option && n.eventtype === type);
        if (noti) return noti;
        else {
          return {
            email: [],
            eventdate: CalculateMonthDayEvent(date, option),
            eventday: option,
            eventyear: CalculateBroadCastYearOfEvent(date, option),
            eventtype: type,
          };
        }
      });
    }
  };
  let notification = [];

  let startDateNotification = createNotification(notificationArr, 'start');
  let endDateNotification = createNotification(notificationArr, 'end');
  notification.push(...startDateNotification);
  notification.push(...endDateNotification);
  return notification;
};

export const isValidSiteFormat = sites => {
  let contentSites = sites.trim();
  let sitesArray = contentSites.split(',');
  var arr1 = [
    'Early Bird Books',
    'The Lineup',
    'The Portalist',
    'Murder & Mayhem',
    'The Archive',
    'A Love So True',
    'All',
  ];
  var arr2 = [
    'Early Bird Books',
    'The Line Up',
    'The Portalist',
    'Murder & Mayhem',
    'Explore The Archive',
    'A Love So True',
    'All',
  ];
  let trimcontentSites = sitesArray.map(item => item.trim());
  for (let i = 0; i < trimcontentSites.length; i++) {
    if (!arr1.includes(trimcontentSites[i]) && !arr2.includes(trimcontentSites[i])) {
      return false;
    }
  }
  return true;
};

export const isbnFormat = isbns => {
  let isbnstrimmed = isbns.trim();
  let isbnArray = isbnstrimmed.split(',');
  let isbnArraytrimmed = isbnArray.map(item => item.trim());
  return isbnArraytrimmed;
};

export const sitesFormat = msitesdata => {
  let sitestrimmed = msitesdata.trim();
  let sitesArray = sitestrimmed.split(',');
  let sitesArraytrimmed = sitesArray.map(item => item.trim());
  let msites = [...sitesArraytrimmed];
  if (msites.length > 0) {
    for (let i = 0; i < msites.length; i++) {
      var marr = ['The Lineup', 'The Archive'];
      if (msites[i] === marr[0]) {
        msites[i] = 'The Line Up';
      }
      if (msites[i] === marr[1]) {
        msites[i] = 'Explore The Archive';
      }
    }
  }
  return msites;
};

/////////////////////////VALIDATION FOR MILESTONE EVENTS//////////////////////////////////////
////////// Validate CSV header fields for milestone events///////////////////////////////////
export const validateEventsCSVHeaders = headerFields => {
  let errors = '';
  let columnsNotFound = [];
  let bad = false;
  let fields = ['Event Name', 'Type', 'Frequency', 'Start Date', 'End Date'];
  let element = headerFields.map(v => v.toLowerCase());
  for (let i = 0; i < fields.length; i++) {
    if (!element.includes(fields[i].toLowerCase())) {
      bad = true;
      columnsNotFound.push(fields[i]);
    }
  }
  if (bad) {
    errors = 'Invalid Csv : Column(s) not found (' + columnsNotFound.join(',') + ')';
  }
  return errors;
};

////////////////////////////VALIDATION OF CSV DATA/////////////////////////////////
export const validateEventsCSVData = csvData => {
  let errors = [],
    fieldError = [];
  let headerFields = [
    {
      name: 'Event Name',
      validate: (field, name) => {
        if (!field) return `${name} is required`;
      },
    },
    {
      name: 'Type',
      validate: (field, name) => {
        if (!field) return `${name} is required`;
      },
    },
    {
      name: 'Frequency',
      validate: (field, name) => {
        if (!field) {
          return `${name} is required`;
        }
      },
    },
    {
      name: 'Start Date',
      validate: (field, name) => {
        if (!field) {
          return `${name} is required`;
        }
        if (field && !isValidDateFormat(field, 'MM/DD/YYYY', true)) {
          return `Formatting Error ${field}: Check the formatting of ${name}`;
        }
      },
    },
    {
      name: 'End Date',
      validate: (field, name) => {
        if (!field) {
          return `${name} is required`;
        }
        if (field && !isValidDateFormat(field, 'MM/DD/YYYY', true)) {
          return `Formatting Error ${field}: Check the formatting of ${name}`;
        }
      },
    },
    {
      name: 'Content Site',
      validate: (field, name) => {
        if (!field) {
          return;
        }
        if (field && !isValidSiteFormat(field)) {
          return `Site Error ${field}: Check the formatting of ${name}`;
        }
      },
    },
    {
      name: 'Primary ISBN',
      validate: (field, name) => {
        if (!field) {
          return;
        }
      },
    },
    {
      name: 'Description',
      validate: (field, name) => {
        if (!field) {
          return;
        }
      },
    },
  ];

  for (var i = 0; i < csvData.length; i++) {
    let row = csvData[i];
    var count = 0;
    const fields = Object.keys(row);
    for (var j = 0; j < fields.length; j++) {
      let field = fields[j];
      if (row.hasOwnProperty(field)) {
        fieldError = headerFields[count].validate(row[field], field);
        if (fieldError) {
          errors.push({ error: fieldError, title: row['Event Name'] });
        }
        ++count;
      }
    }
  }
  return errors;
};

////////////////////////////FORMAT OF CSV DATA////////////////////////////////
export const formatEventsCSVData = data => {
  let formatedCSV = [];
  if (data.length) {
    for (var i = 0; i < data.length; i++) {
      let contentSitesdata = sitesFormat(data[i]['Content Site'].trim());
      let books = isbnFormat(data[i]['Primary ISBN']);
      let sites = [];
      let bookdata = [];
      let rowData = {
        title:
          data[i]['event name'] ||
          data[i]['Event Name'] ||
          data[i]['Event name'] ||
          data[i]['event Name'] ||
          data[i]['EventName'] ||
          data[i]['eventname'],
        type: data[i]['type'] || data[i]['Type'],
        frequency: data[i]['frequency'] || data[i]['Frequency'],
        startdate: data[i]['start date'] || data[i]['Start Date'] || data[i]['start Date'] || data[i]['Start date'],
        enddate: data[i]['end date'] || data[i]['End Date'] || data[i]['end Date'] || data[i]['Start date'],
        description: data[i]['Description'] ? data[i]['Description'] : '',
      };
      //Insert Content Sites
      if (contentSitesdata && contentSitesdata.length > 0) {
        contentSitesdata.map(site => sites.push(site));
      } else {
        sites.push('ALL');
      }
      rowData.contentSite = sites;

      //get the book details
      //Insert books
      if (books && books.length >= 0) {
        for (let j = 0; j < books.length; j++) {
          let item = {};
          item.primary_isbn = books[j];
          bookdata.push(item);
        }
        rowData.books = bookdata;
      }
      formatedCSV.push(rowData);
    }
  }
  return formatedCSV;
};

export const validateEmailFormat = email => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validatePassword = password => {
  const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
  return re.test(password);
};

//input arr : array of string
//output: array of object {value: "", label: ""}
export const getSelectOptions = arr => {
  if (arr?.length > 0) {
    return arr.map(value => ({ value: value, label: value }));
  } else {
    return [];
  }
};

export const getCurrentDate = () => {
  let today = new Date();
  today.setHours(0, 0, 0, 0);
  today = FormatDateString(today, 'YYYY/MM/DD');
  return today;
};

export const formatAudioContractRequiredField = obj => {
  return {
    ormContractDate: _formatDate(obj.ormContractDate, 'MM/dd/yyyy'),
    ormAudioExpiration: _formatDate(obj.ormAudioExpiration, 'MM/dd/yyyy'),
    ormAutoRenew: obj.ormAutoRenew,
    audioPublisherContractDate: _formatDate(obj.audioPublisherContractDate, 'MM/dd/yyyy'),
    audioPublisherExpiration: _formatDate(obj.audioPublisherExpiration, 'MM/dd/yyyy'),
    audioPublisherAutoRenew: obj.audioPublisherAutoRenew,
    physicalRoyalties: obj.physicalRoyalties ? `${obj.physicalRoyalties}%` : '',
    digitalRoyalties: obj.digitalRoyalties ? `${obj.digitalRoyalties}%` : '',
    ormAutoRenewTerm: obj.ormAutoRenewTerm,
    audioPublisherAutoRenewTerm: obj.audioPublisherAutoRenewTerm,
    paySchedule: obj.paySchedule ? obj.paySchedule?.join(',') : '',
    audioBookPubDate: _formatDate(obj.audioBookPubDate, 'MM/dd/yyyy') || '',
    audioPublisher: obj.audioPublisher,
    ormContractStatus: obj.ormContractStatus,
    audioPublisherContractStatus: obj.audioPublisherContractStatus,
    language: obj.language,
    advance: obj.advance || '',
    agent: obj.agent || '',
    comment: obj.comment || '',
  };
};

export const formatAudioContractDataForTable = data => {
  let formatData = [];
  if (data && data.length > 0) {
    formatData = data.map(value => {
      const book = value.Books && value.Books[0];
      const bookData = value.BookData && value.BookData[0];
      return {
        _id: value._id,
        id: value._id,
        ...formatAudioContractRequiredField(value),
        primaryIsbn: value.primaryIsbn,
        active: value.active,
        dlp: bookData?.US.dlp || '',
        title: book?.title,
        territorydesc: book?.territorydesc || '',
        primaryAuthorNames: book?.primaryAuthorNames || '',
        pub_date: _formatDate(book?.pub_date, 'MM/dd/yyyy'),
        bisacs: book?.bisacs || '',
        frontlist: book?.frontlist || '',
        imprint: book?.imprint || '',
      };
    });
  }
  return formatData;
};

// Format CSV data into service request schema modal
export const formatServiceRequestData = data => {
  if (!data || Object.keys(data).length === 0) return {};
  return {
    title: data.Title,
    primaryIsbn: data.ISBN,
    asin: data.ASIN,
    authors: data.Authors,
    dlp: data.DLP,
    saleDlp: data.SaleDLP,
    runDate: data.RunDate && FormatDateTime(data.RunDate).format('YYYY-MM-DD'),
    businessUnit: data.BusUnit,
    serviceType: data['Service Type'],
    serviceStatus: 'Scheduled',
    transactionId: data.transactionId,
    transactionName: data.transactionName,
    trackingId: data.trackingId,
    url: data.Url,
    displayAdsType: data['Display Ads Type'],
    startDate: data['Start Date'] && FormatDateTime(data['Start Date']).format('YYYY-MM-DD'),
    endDate: data['End Date'] && FormatDateTime(data['End Date']).format('YYYY-MM-DD'),
    campaignName: data['Campaign Name']
      ? data['Campaign Name']
      : ACTIVATION_BUSINESS_UNIT.get(data.BusUnit)?.campaignName,
    active: true,
    pubDate: data.pubDate,
  };
};

export const isValidURL = url => {
  if (!url?.trim()) return false;
  try {
    new URL(url);
    return true;
  } catch (err) {
    return false;
  }
};

export const downloadCSV = (data, fileName) => {
  let csv = Papa.unparse(data, { skipEmptyLines: true });
  let csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  if (window.navigator.msSaveOrOpenBlob) window.navigator.msSaveBlob(csvData, 'download.csv');
  else {
    let a = window.document.createElement('a');
    a.href = window.URL.createObjectURL(csvData, { type: 'text/plain' });
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    ormToast('CSV download successfully', TOAST_TYPE.SUCCESS);
  }
};

export function debounce(fn, delay = 300) {
  let timeout;

  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fn(...args);
    }, delay);
  };
}

export const getValueForDropdown = (options, value) => {
  return options.find(option => option.value === value) || '';
};

// This function take a string text and update sqareBracket value using dynamicValues object
// text should be => "Filter date should be [[startdate]]"
// dynamicValues should be => {startdate: 04/04/2024}
export const updateDynamicTextValues = (text, dynamicValues) => {
  // Regular expression to match values within square brackets
  var regex = /\[\[([^[\]]*)\]\]/g;
  // Replace all matches with corresponding dynamic values
  var updatedTitle = text.replace(regex, function (match, key) {
    return dynamicValues[key] || match; // Use the dynamic value if available, otherwise keep the original match
  });

  return updatedTitle;
};

export const textColorStatus = str => {
  const status = str?.toUpperCase();
  if (status === 'FAILED') {
    return 'error';
  } else if (status === 'QUEUE') {
    return '#ff4d01';
  } else if (status === 'SCHEDULED') {
    return 'primary';
  } else if (status === 'SUCCESS' || status === 'DONE') {
    return 'success.main';
  }
};

export const validateEmail = email => {
  let res = email.split(',');
  res = res.map(v => v.trim());
  let validEmail = [];
  for (let i = 0; i < res.length; i++) {
    let re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    validEmail.push(re.test(res[i]));
  }
  if (!validEmail.some(e => e === false)) {
    return true;
  }
};

/**
 * Prevents changes to the input value and container scrolling when the mouse wheel is used.
 *
 * This function is intended to be used as an event handler for the 'wheel' event on number input elements.
 * It ensures that the input value does not change and the container does not scroll when the mouse wheel
 * is used over the input.
 *
 * @param {Event} e - The wheel event object.
 */
export const preventOnWheelChange = e => {
  // Prevent the input value change
  e.target.blur();

  // Prevent the container scrolling
  e.stopPropagation();
};

/**
 * Utility function to update a nested property within an object.
 * @param {Object} obj - The object to update.
 * @param {String} path - The dot-separated path of the property to update.
 * @param {*} value - The value to set at the specified property path.
 * @returns {Object} - A new object with the updated property.
 */
export const updateNestedState = (obj, path, value) => {
  // Split the path into keys
  const keys = path.split('.');

  // Make a shallow copy of the object to avoid mutating the original object
  let newObj = { ...obj };
  let current = newObj;

  // Iterate through the keys to traverse the nested structure
  for (let i = 0; i < keys.length - 1; i++) {
    // If the key does not exist, create an empty object at that key
    if (!current[keys[i]]) {
      current[keys[i]] = {};
    } else {
      // Make a shallow copy to avoid mutating the original nested object
      current[keys[i]] = { ...current[keys[i]] };
    }
    current = current[keys[i]];
  }

  // Set the value at the specified property path
  current[keys[keys.length - 1]] = value;

  return newObj;
};

// Function to determine the final territory list based on title information
export const fetchFinalIncludeTerritory = (countryList, titleInfo) => {
  let finalTerritory = countryList;

  // Filter for exclusive territories if they exist in the title information
  if (titleInfo.exclusiveterritory) {
    finalTerritory = finalTerritory.filter(country => titleInfo.exclusiveterritory.includes(country));
  }

  // Exclude non-exclusive territories if they exist in the title information
  if (titleInfo.nonexclusiveterritory) {
    finalTerritory = finalTerritory.filter(country => titleInfo.nonexclusiveterritory.includes(country));
  }

  // Exclude "not for sale" territories if they exist in the title information
  if (titleInfo.notforsaleterritory) {
    finalTerritory = finalTerritory.filter(country => !titleInfo.notforsaleterritory.includes(country));
  }

  return finalTerritory;
};
