import React, { createContext, useContext, useEffect, useState, useRef } from 'react';

const WebSocketContext = createContext();

export const WebSocketProvider = ({ children }) => {
  const [webSocket, setWebSocket] = useState(null);
  const pingIntervalRef = useRef(null);

  const createWebSocket = () => {
    console.log(process.env.REACT_APP_ORION_ENV);
    let wsUrl = "wss://local.step.openroadmedia.com";
    if (process.env.REACT_APP_ORION_ENV === "staging") 
      wsUrl = "wss://staging-ecf.step.openroadmedia.com";
    if (process.env.REACT_APP_ORION_ENV === "production") 
      wsUrl = "wss://step-ecf.openroadmedia.com";
    console.log('Going to connect - ', wsUrl);
    
    const socket = new WebSocket(wsUrl);

    socket.addEventListener('open', () => {
      console.log('WebSocket connection opened.');
      pingIntervalRef.current = setInterval(() => {
        if (socket.readyState === WebSocket.OPEN) {
          socket.send(JSON.stringify({ type: 'ping' }));
          console.log('Ping sent to server');
        }
      }, 50000); // Ping every 50 seconds
    });

    socket.addEventListener('message', (event) => {
      const message = JSON.parse(event.data);
      console.log('WebSocket message:', message);
      if (message.type === 'pong') {
        console.log('Received pong from server');
      }
    });

    socket.addEventListener('error', (error) => {
      console.error('WebSocket error:', error);
    });

    socket.addEventListener('close', (event) => {
      console.log('WebSocket connection closed:', event.code, event.reason);
      clearInterval(pingIntervalRef.current);
      setTimeout(createWebSocket, 1000); // Reconnect attempt
    });

    setWebSocket(socket);
  };

  useEffect(() => {
    createWebSocket();

    return () => {
      if (webSocket) {
        console.log('Closing WebSocket connection');
        webSocket.close();
      }
      clearInterval(pingIntervalRef.current);
    };
  }, []);

  return (
    <WebSocketContext.Provider value={webSocket}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => useContext(WebSocketContext);
