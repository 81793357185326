import React from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import { MenuSharp } from '@mui/icons-material';

export default function WordWrapButton({ handleMenuClick }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenu = toggle => {
    handleClose();
    handleMenuClick(toggle);
  };

  return (
    <>
      <Button
        id='word-wrap-button'
        aria-controls={open ? 'word-wrap-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        size='small'
        startIcon={<MenuSharp />}
      >
        Density
      </Button>
      <Menu
        id='word-wrap-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'word-wrap-button',
        }}
      >
        <MenuItem onClick={() => handleMenu(false)}>Comfort</MenuItem>
        <MenuItem onClick={() => handleMenu(true)}>Compact</MenuItem>
      </Menu>
    </>
  );
}
