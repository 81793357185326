///////////////////////////////////////////////////////////////////////////////
//                              IMAGE FUNCTIONS                              //
///////////////////////////////////////////////////////////////////////////////
/* 
image should be url like https://book-assets.openroadmedia.com/9781614754770.jpg
we will pop just the image name always and use it with different cloudfront which is created by serverless-image-handler. Client will pass require width & height value.
*/
export const BuildServerlessBookCoverImageUrl = ({ width, height }, imageName) => {
  //If for any reason, serverless image handler is disabled than use regular cloudfront URL
  if (process.env.REACT_APP_ENABLE_SLS_IMAGEHANDLER === 'false') return imageName;
  if (imageName) {
    //Internal book cover is full url like https://book-assets.openroadmedia.com/9781614754770.jpg, we only need name which is lastSegment
    const lastSegment = imageName.split('/').pop();
    if (process.env.REACT_APP_ENABLE_SLS_IMAGEHANDLER === 'true' && width)
      return `${process.env.REACT_APP_SLS_IMAGE_CLDFRONT}/${width}x${height}/${lastSegment}`;
    else return `${process.env.REACT_APP_SLS_IMAGE_CLDFRONT}/${lastSegment}`;
  }
  return `${process.env.REACT_APP_SLS_IMAGE_CLDFRONT}/${imageName}`;
};
