import dayjs from 'dayjs';
import { validateEmailFormat } from 'src/utils/Helper';
import { getOrdinalSuffix } from 'src/utils/TriggerTitleHelper';
import ormToast, { TOAST_TYPE } from 'src/utils/ormToast';

export const validateTriggerTitleForm = values => {
  let isError = false;
  let errorsObject = {};
  if (!values.name) {
    errorsObject.name = 'Field is required';
    isError = true;
  } else {
    errorsObject.name = '';
  }
  if (!values.minTitleCount && values.minTitleCount !== 0) {
    errorsObject.minTitleCount = 'Field is required';
    isError = true;
  } else {
    errorsObject.minTitleCount = '';
  }

  if (values.minTitleCount && values.maxTitleCount) {
    if (values.minTitleCount > values.maxTitleCount) {
      errorsObject.minTitleCount = 'The title count should be less than to max.';
      isError = true;
    }
  }
  if (!values.schedule.type) {
    errorsObject.frequencyType = 'Please select schedule type';
    isError = true;
  }
  if (values.schedule.type === 'Weekly' || values.schedule.type === 'Bi-Weekly') {
    if (!values.schedule.weekDay?.value) {
      errorsObject.frequencyWeekDay = 'Please select days';
      isError = true;
    }
  }
  if (values.schedule.type === 'Monthly') {
    if (!values.schedule.fixedDay) {
      errorsObject.frequencyFixedDay = 'Field is required';
      isError = true;
    }
  }
  if (values.destination?.orionPostUpdate) {
    const site = values.destination.orionPostUpdate.site ?? {};
    const postDetail = values.destination.orionPostUpdate.postDetail ?? {};

    if (Object.keys(site).length > 0 && Object.keys(postDetail).length === 0) {
      errorsObject.orionPostUpdate =
        'Push Trigger Titles to Orion Post Configuration missing slug. If you do not want to use this functionality, please uncheck site';
    }
  }
  if (values.destination.campaignType && values.destination.campaignName?.trim()) {
    if (
      !values.destination?.startingDate?.numberOfDaysForTriggerSchedule &&
      !values.destination?.startingDate?.occurOn?.value &&
      !values.destination?.startingDate?.occurDay
    ) {
      errorsObject.destinationStartingNumberOfDays = 'Field is reqreuid';
      errorsObject.destinationStartingOccurOn = 'Please select week';
      errorsObject.destinationStartingOccurDay = 'Please select day';
      isError = true;
    }
    if (values.schedule.type !== 'Daily' && values.destination?.startingDate?.numberOfDaysForTriggerSchedule) {
      if (!values.destination?.endingDate?.relativeEndDays && !values.destination?.endingDate?.endOfMonth) {
        errorsObject.destinationEndingRelativeDays = 'Field is reqreuid';
        errorsObject.destinationEndofMonth = 'Field is required';
        isError = true;
      }
    }
  }
  if (values.notifications.fail) {
    const emails = values.notifications.fail.split(',');
    emails.forEach(email => {
      if (!validateEmailFormat(email?.trim())) {
        errorsObject.notificationFail = 'Please enter valid email Id';
        isError = true;
      }
    });
  }
  if (values.notifications.success) {
    const emails = values.notifications.success.split(',');
    emails.forEach(email => {
      if (!validateEmailFormat(email?.trim())) {
        errorsObject.notificationSuccess = 'Please enter valid email Id';
        isError = true;
      }
    });
  }
  if (values.preventTitleRepetition && (!values.numberOfRunsToPreventTitleRepetition || values.numberOfRunsToPreventTitleRepetition === '0')) {
    errorsObject.numberOfRunsToPreventTitleRepetition = 'Required';
    isError = true;
  } else {
    errorsObject.numberOfRunsToPreventTitleRepetition = '';
  }
  if (values.sorting.asc?.length > 0 && values.sorting.desc?.length > 0) {
    const isDuplicate = values.sorting.asc.some(ascValue =>
      values.sorting.desc.some(descValue => descValue.value === ascValue.value)
    );
    if (isDuplicate) {
      ormToast('Sorting should not be same in asc and desc order', TOAST_TYPE.ERROR);
      isError = true;
    }
  }
  return { isError, errorsObject };
};

export const checkRenderFilterValue = data => {
  let isError = false;
  for (let filters of data) {
    for (let childFilters of filters.childFilters) {
      const { type, value, controlType } = childFilters;
      if (type === 'Boolean' || !controlType?.isRequired) continue;
      if (!value || (typeof value === 'string' && !value?.trim())) {
        isError = true;
        break;
      }
      if (value && Array.isArray(value) && value?.length === 0) {
        isError = true;
        break;
      }
      if (value && Object.keys(value)?.length === 0) {
        isError = true;
        break;
      }
    }
    if (isError) break;
  }

  return isError;
};

// This function loop through post processing data and check 100% criteria
export const validatePostProcessingValue = data => {
  let isError = false;
  for (let key in data) {
    if (data[key]?.length > 0) {
      let total = 0;
      for (let filter of data[key]) {
        if (filter.fieldValue) {
          total += filter.fieldValue;
        }
      }
      if (total !== 100) {
        isError = true;
      }
    }
    if (isError) break;
  }

  return isError;
};

// This function take schedule(frequncy value) and and return date based on setup
export const findNextRunDate = values => {
  const EST_TIME_FORMAT = 'MM/DD/YYYY hh:mm A [EST]';
  const hour = dayjs(values.time).isValid() ? dayjs(values.time).get('hour') : values.time.hour;
  const minute = dayjs(values.time).isValid() ? dayjs(values.time).get('minute') : values.time.minute;
  let nextExecution;
  switch (values.type) {
    case 'Daily': {
      nextExecution = dayjs().hour(hour).minute(minute);
      if (nextExecution <= dayjs()) {
        nextExecution = nextExecution.add(1, 'day');
      }
      break;
    }
    case 'Weekly':
    case 'Bi-Weekly': {
      if (values.weekDay) {
        nextExecution = getNextDateUsingDayAndTime(values.weekDay?.value, hour, minute);
      }
      break;
    }
    case 'Monthly': {
      if (values.fixedDay) {
        const currentDate = dayjs().get('date');
        if (currentDate < Number(values.fixedDay)) {
          nextExecution = dayjs().date(values.fixedDay).hour(hour).minute(minute);
        } else {
          nextExecution = dayjs().add(1, 'M').date(values.fixedDay).hour(hour).minute(minute);
        }
      }
      break;
    }
    default: {
      break;
    }
  }
  return nextExecution?.hour(hour).minute(minute).format(EST_TIME_FORMAT) || '';
};

/**
 * Get the next date with time based on the target day, hour, and minute.
 *
 * @param {number} targetDay - The target day of the week (0-6, where 0 is Sunday and 6 is Saturday).
 * @param {number} targetHour - The target hour of the day (0-23).
 * @param {number} targetMinute - The target minute of the hour (0-59).
 * @returns {dayjs.Dayjs} The next date and time that matches the target day, hour, and minute.
 */
export const getNextDateUsingDayAndTime = (targetDay, targetHour, targetMinute) => {
  // Get the current date and time
  const now = dayjs();

  // Get the current day, hour, and minute
  const currentDay = now.day();
  const currentHour = now.hour();
  const currentMinute = now.minute();

  // Calculate the difference in days between the current day and the target day
  let dayDifference = targetDay - currentDay;

  // If the target day is earlier in the week than the current day,
  // or it's the same day but the target time has already passed,
  // add 7 days to the day difference to get the next occurrence of the target day
  if (
    dayDifference < 0 ||
    (dayDifference === 0 &&
      (currentHour > targetHour || (currentHour === Number(targetHour) && currentMinute >= Number(targetMinute))))
  ) {
    dayDifference += 7;
  }

  // Calculate the next date and time by adding the day difference
  // and setting the target hour, minute, second, and millisecond
  return now.add(dayDifference, 'day').hour(targetHour).minute(targetMinute).second(0).millisecond(0);
};

export const findDestinationStartDateEndDate = (values, scheduleValues) => {
  const { startingDate, endingDate } = values || {};
  let startDate, endDate;
  const nextRunDate = findNextRunDate(scheduleValues);
  if (nextRunDate) {
    // Find Start Date
    if (startingDate.numberOfDaysForTriggerSchedule) {
      startDate = dayjs(nextRunDate).add(startingDate.numberOfDaysForTriggerSchedule, 'day')?.format('MM/DD/YYYY');
      if (endingDate.relativeEndDays) {
        endDate = dayjs(startDate).add(endingDate.relativeEndDays, 'd')?.format('MM/DD/YYYY');
      } else if (endingDate.endOfMonth) {
        const currentDate = dayjs(startDate); // Get the current date
        endDate = currentDate.endOf('month')?.format('MM/DD/YYYY'); // Get the end of the current month
      } else {
        endDate = startDate;
      }
    } else if (startingDate.occurOn?.value && startingDate.occurDay?.value) {
      const occurDay = startingDate.occurDay?.value;
      startDate = findNthWeekdayOfMonth(startingDate.occurOn.value, occurDay, nextRunDate)?.format('MM/DD/YYYY');
      endDate = startDate;
    }
  }
  return { startDate, endDate };
};

// This function calculates the date of the nth weekday (e.g., 1st Monday, 2nd Tuesday)
// in the current month based on the given week number and day of the week.
function findNthWeekdayOfMonth(weekNumber, dayOfWeek, date) {
  // Get the current date
  let currentDate = dayjs(date).startOf('month');

  // Find the first occurrence of the desired day of the week in the month
  let firstDay = currentDate.day();
  let diff = (dayOfWeek - firstDay + 7) % 7;
  let firstOccurrence = currentDate.add(diff, 'day');

  // Calculate the date of the nth weekday in the month
  let nthWeekday = firstOccurrence.add((weekNumber - 1) * 7, 'day');
  if (nthWeekday < dayjs(date)) {
    nthWeekday = firstOccurrence
      .add(1, 'month')
      .startOf('month')
      .add((weekNumber - 1) * 7, 'day');
  }

  return nthWeekday;
}

export const getDestinationInfoText = (destination, schedule, minTitleCount, maxTitleCount) => {
  if (destination) {
    const count = maxTitleCount ? `${minTitleCount} - ${maxTitleCount}` : 'All';
    const { campaignType, campaignName } = destination || {};
    const campaignTypeName = campaignType?.text;
    const { startDate, endDate } = findDestinationStartDateEndDate(destination, schedule);
    let campaignNameDisplay = campaignName || 'Find All campaign name with same campaign type';

    let action;
    switch (destination.type) {
      case 'CREATE':
        action = 'create a new';
        break;
      case 'UPDATE':
        action = 'update/create a';
        break;
      case 'CLONE':
        action = 'create a clone of an existing';
        break;
      default:
        action = '';
    }

    let campaignDisplayName;
    if (destination.type === 'CLONE') {
      campaignDisplayName = `<b>Trigger_Clone_${campaignName || campaignTypeName}</b>`;
    } else if (destination.type === 'CREATE') {
      campaignDisplayName = `<b>Trigger_${campaignName || campaignTypeName}</b>`;
    } else {
      campaignDisplayName = `<b>${campaignNameDisplay}</b>`;
    }

    let postUpdateMsg = '',
      linkUrl = ``;
    console.log('destination ', destination);
    if (
      destination?.orionPostUpdate &&
      Object.keys(destination?.orionPostUpdate?.site ?? {}).length > 0 &&
      Object.keys(destination?.orionPostUpdate?.postDetail ?? {}).length > 0
    ) {
      if (process.env.REACT_APP_ORION_ENV === 'local' || process.env.REACT_APP_ORION_ENV === 'development')
        linkUrl = `http://local.${destination.orionPostUpdate.site.domain}/${destination.orionPostUpdate.postDetail.value}`;
      else if (process.env.REACT_APP_ORION_ENV === 'staging')
        linkUrl = `https://staging.${destination.orionPostUpdate.site.domain}/${destination.orionPostUpdate.postDetail.value}`;
      else
        linkUrl = `https://${destination.orionPostUpdate.site.domain}/${destination.orionPostUpdate.postDetail.value}`;

      postUpdateMsg = `
      ORION POST --> Your trigger configuration will also update ${linkUrl}
      `;
    }
    return campaignDisplayName && campaignTypeName
      ? `Based on your trigger configuration, the system will ${action} campaign with ${count} titles.<br/>
        Campaign Name: ${campaignDisplayName}<br/> 
        campaign Type : <b>${campaignTypeName}</b><br/>
        Campaign Start Date:  <b>${startDate || '-'}</b><br/>
        Campaign End Date:  <b>${endDate || '-'}</b><br/>
        <hr/>
        ${postUpdateMsg}`
      : postUpdateMsg;
  } else {
    return 'API JSON';
  }
};

export const getFrequencyInfoText = schedule => {
  const time = dayjs(schedule.time).isValid()
    ? dayjs(schedule.time).format('hh:mm A [EST]')
    : dayjs().hour(schedule.time?.hour).minute(schedule.time?.minute).format('hh:mm A [EST]');
  let finalMessage,
    runOn,
    type = 'Daily';
  if (schedule) {
    if (schedule.type === 'Bi-Weekly') type = 'Every Two Week';
    if (schedule.type === 'Weekly') type = 'Every Week';
    if (schedule.type === 'Monthly') type = 'Every Month';

    if (schedule.type === 'Daily') {
      finalMessage = `Your trigger will run ${type} at ${time}`;
    }

    if (schedule.type === 'Weekly' || schedule.type === 'Bi-Weekly') {
      let dys = schedule.weekDay?.label || '-';

      finalMessage = `Your trigger will run on ${dys} ${type} at ${time}`;
    }
    if (schedule.type === 'Monthly') {
      runOn = schedule.fixedDay ? getOrdinalSuffix(schedule.fixedDay) : '-';
      finalMessage = `Your Trigger Titles will run ${runOn} of ${type} at ${time}`;
    }
  }
  return finalMessage;
};
