export const ACTIVATION_SERVICE_TYPE = [
  { label: 'Sold Slot', value: 'Sold Slot' },
  // {label: 'Dedicated Emails', value: 'Dedicated Emails'}
];

export const ACTIVATION_PAYMENT_STATUS = [
  { label: 'Unpaid', value: 'Unpaid' },
  { label: 'Paid', value: 'Paid' },
];

export const ACTIVATION_SOURCE = [
  { label: 'Partner', value: 'Partner' },
  { label: 'Online', value: 'Online' },
];

export const ACTIVATION_SERVICE_REQ_FORMAT = [
  { label: 'Digital', value: 'Digital' },
  { label: 'Print', value: 'Print' },
  { label: 'Audio', value: 'Audio' },
  { label: 'Other', value: 'Other' },
];

export const ACTIVATION_BUSINESS_UNIT = new Map([
  ['EBB', { label: 'EBB', value: 'EBB', campaignType: 13, campaignName: 'AM' }],
  ['TLU', { label: 'TLU', value: 'TLU', campaignType: 22, campaignName: 'Weekly' }],
  ['PORT', { label: 'PORT', value: 'PORT', campaignType: 20, campaignName: 'Weekly' }],
  ['M&M', { label: 'M&M', value: 'M&M', campaignType: 15, campaignName: 'Weekly' }],
  ['ARCH', { label: 'ARCH', value: 'ARCH', campaignType: 18, campaignName: 'Weekly' }],
  ['ALST', { label: 'ALST', value: 'ALST', campaignType: 36, campaignName: 'Weekly' }],
  ['READER', { label: 'READER', value: 'READER', campaignType: 24, campaignName: 'Weekly' }],
]);

export const DISPLAY_ADS_TYPE = ['NEWSLETTER', 'WEBSITE'];

export const ACTIVATION_CLIENT_CONTACT_TYPE = [
  { label: 'Finance', value: 'Finance' },
  { label: 'Other', value: 'Other' },
];

export const ACTIVATION_CLIENT_PRICING_MODEL = [
  { label: 'Agency', value: 'Agency' },
  { label: 'WholeSale', value: 'WholeSale' },
];

export const SERVICE_STATUS = Object.freeze({
  SCHEDULED: 'Scheduled',
  COMPLETED: 'Completed',
  NOT_SCHEDULED: 'Not Scheduled',
});

export const SERVICE_TYPE = Object.freeze({
  SOLD_SLOT: 'Sold Slot',
  DISPLAY_ADS: 'Display Ads',
  DEDICATED_EMAILS: 'Dedicated Emails',
  GIVEAWAYS: 'GiveAways',
  SPONSORED_CONTENT: 'Sponsored Content',
});

export const serviceStatusFilterOptions = [
  {
    label: 'All',
    value: 'All',
  },
  {
    label: 'Scheduled',
    value: 'Scheduled',
  },
  {
    label: 'Not Scheduled',
    value: 'Not Scheduled',
  },
  {
    label: 'Completed',
    value: 'Completed',
  },
];
