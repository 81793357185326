import { FormatDateString } from './DateTimeHelper';
import ormToast, { TOAST_TYPE } from './ormToast';

export const dateValueForEdit = ({ value }) => {
  if (value) return new Date(FormatDateString(value, 'MM/DD/YYYY'));
  else return '';
};

export const renderURLCell = ({ row, value }) => {
  const copyFeedUrl = url => {
    var textField = document.createElement('textarea');
    textField.innerText = url;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    ormToast('URL copied!', TOAST_TYPE.INFO);
  };
  let url = value;
  if (url && row.trackingId) {
    if (url.includes('?')) url += `&trackingId=${row.trackingId}`;
    else url += `?trackingId=${row.trackingId}`;
  }
  return (
    <div style={{ color: 'rgb(13, 110, 253)' }} onClick={() => copyFeedUrl(url)} title='Click to copy url'>
      {url}
    </div>
  );
};
