import React from 'react';

const TransactionTableItem = ({ transactionId, children, handleTransactionItemClick }) => {
  return (
    <span
      style={{ textDecoration: 'underline', color: '#0d6efd' }}
      onClick={() => {
        handleTransactionItemClick(transactionId);
      }}
    >
      {children}
    </span>
  );
};

export default TransactionTableItem;
