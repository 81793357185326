import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Container } from '@mui/material';
import ORMDataGridV2 from '../../Shared/Table/ORMDataGridV2';
import { TRIGGER_TITLE_RESULT_PARENT_COLUMNS } from 'src/constants/dataGridColumns';
import APIService from 'src/utils/APIService';
import RenderSpinner from '../../Spinner';
import Heading from '../../Shared/UI/Heading';
import { Download } from '@mui/icons-material';
import { downloadCSV } from 'src/utils/Helper';
import ormToast, { TOAST_TYPE } from 'src/utils/ormToast';
import TriggerTitlesChildTable from './TriggerTitlesChildTable';

const TriggerTitlesResult = () => {
  const [triggerResults, setTriggerResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState([]);

  const dataGridRef = useRef();

  const fetchData = useCallback(async () => {
    const response = await APIService.get('/triggertitle/result');
    if (response.status === 200) {
      setTriggerResults(response.data);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
    document.title = 'View Trigger Result - Peggy';
  }, [fetchData]);

  const getDetailPanelContent = ({ row }) => {
    if (detailPanelExpandedRowIds?.includes(row._id)) {
      return <TriggerTitlesChildTable id={row._id} />;
    } else {
      return <></>;
    }
  };

  const handleCSVExport = async () => {
    if (!selectedRows || selectedRows?.length === 0) {
      ormToast('Please Select Row!', TOAST_TYPE.ERROR);
      return;
    }
    setLoading(true);
    const ids = selectedRows.map(value => value._id);
    try {
      const response = await APIService.post('/triggertitle/result/export', { ids });
      if (response.status === 200) {
        //Dowload all titles in csv format
        let filename = 'Trigger Title Result.csv';
        downloadCSV(response.data, filename);
        setLoading(false);
      } else {
        ormToast(response.data?.message, TOAST_TYPE.ERROR);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Container>
      {loading && <RenderSpinner message='Loading....' overlay={true} OverlayTrigger={true} />}
      <Heading heading='View Trigger Result' />
      <ORMDataGridV2
        columns={TRIGGER_TITLE_RESULT_PARENT_COLUMNS}
        rows={triggerResults}
        ref={dataGridRef}
        getDetailPanelContent={getDetailPanelContent}
        checkboxSelection
        onRowSelectionChange={setSelectedRows}
        toolbarOptions={['COLUMNS', 'FILTER', 'DENSITY', 'SEARCH']}
        onDetailPanelExpandedRowIdsChange={setDetailPanelExpandedRowIds}
        pagination
      >
        <Button size='small' sx={{ ml: 1 }} onClick={handleCSVExport}>
          <Download sx={{ mr: 1 }} />
          Export Titles
        </Button>
      </ORMDataGridV2>
    </Container>
  );
};

export default TriggerTitlesResult;
