// ----------------------------------------------------------------------

export default function Container() {
  return {
    MuiContainer: {
      defaultProps: {
        maxWidth: false,
      },
    },
  };
}
