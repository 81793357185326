import React, { useContext, lazy, Suspense } from 'react';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { UserContext } from './UserContextProvider';
import RenderSpinner from './components/Spinner';
import TriggerTitlesResult from './components/TriggerTitles/TriggerTitlesResult/TriggerTitlesResult';
import { ROUTES } from './utils/UserPermissionRoutes';
import PrivateRoute from './auth/PrivateRoute';
const TriggerTitleConfiguration = lazy(() => import('./components/TriggerTitles/TriggerTitleConfiguration'));
const ViewTriggerConfiguration = lazy(() => import('./components/TriggerTitles/ViewTriggerConfiguration'));
const BatchLevelPriorityData = lazy(() => import('./components/PriorityReport/BatchLevelPriorityData'));
const Login = lazy(() => import('./components/Login/Login'));
const HomePage = lazy(() => import('./components/Homepage/Homepage'));
const CampaignHome = lazy(() => import('./components/CampaignHome/CampaignHome'));
const Campaign = lazy(() => import('./components/Campaign/Campaign'));
const CampaignType = lazy(() => import('./components/Campaign/CampaignType'));
const CampaignTypeEdit = lazy(() => import('./components/Campaign/CampaignTypeEdit'));
const NominationHome = lazy(() => import('./components/Nomination/NominationHome'));
const BookEdit = lazy(() => import('./components/BookEdit/BookEdit'));
const BookDetail = lazy(() => import('./components/BookDetail/BookDetail'));
const Blocklist = lazy(() => import('./components/Blocklist/Blocklist'));
const FirebrandReports = lazy(() => import('./components/FirebrandReports/FirebrandReports'));
const SocialData = lazy(() => import('./components/SocialData/SocialData'));
const PageNotFound = lazy(() => import('./components/PageNotFound/PageNotFound'));
const AccessDenied = lazy(() => import('./components/AccessDenied/AccessDenied'));
const FacebookAdManager = lazy(() => import('./components/Campaign/Facebook/FacebookAdManager.js'));
const ExternalTitleUpload = lazy(() => import('./components/ExternalTitle/Upload'));
const TSDReport = lazy(() => import('./components/TSDReport/TSDReport'));
const PriorityReport = lazy(() => import('./components/PriorityReport/PriorityReport'));
const MileStoneHome = lazy(() => import('./components/Milestone/MilestoneHome'));
const AddEvent = lazy(() => import('./components/Calendar/AddEditEvent'));
const UploadMileStoneEvents = lazy(() => import('./components/Milestone/UploadMilestoneEvents'));
const OrionSchedular = lazy(() => import('./components/OrionSchedular/OrionSchedular'));
const CampaignWidgetHome = lazy(() => import('./components/Widgets/CampaignWidgetHome'));
const Users = lazy(() => import('./components/Users/Users'));
const UserRole = lazy(() => import('./components/UserRole/UserRole'));
const ResetPassword = lazy(() => import('./components/Login/reset-password'));
const Submission = lazy(() => import('./components/ExternalSubmission/Submission'));
const ValidateTitle = lazy(() => import('./components/ValidateTitleList/Validatelist'));
const BlastRuleConfiguration = lazy(() => import('./components/RecommendationConfiguration/BlastRuleConfiguration'));
const BlastRecommendationEngine = lazy(() => import('./components/RecommendationResult/BlastRecommendationEngine'));
const HaloRecommendation = lazy(() => import('./components/RecommendationHalo/HaloRecommendation'));
const GenerateRetailerLinks = lazy(() => import('./components/RetailerLinks/GenerateRetailerLinks'));
const ContentDealsConfig = lazy(() => import('./components/RecommendationConfiguration/ContentNLDealsConfiguration'));
const ContentNLDealsRecommendationResult = lazy(() =>
  import('./components/RecommendationResult/ContentNLDealsRecommendationResult')
);
const LendingDealsConfiguration = lazy(() =>
  import('./components/RecommendationConfiguration/LendingDealsConfiguration')
);
const LendingRecommendationResult = lazy(() => import('./components/RecommendationResult/LendingRecommendationResult'));

const CampaignHelpPage = lazy(() => import('./components/CampaignHelp/CampaignHelpPage'));
const PromoPrice = lazy(() => import('./components/DynamicPromoPrice/Promoprice'));
const CCMapping = lazy(() => import('./components/CustomCategory/CCMapping'));
const NewServiceRequest = lazy(() => import('./components/Activation/ServiceRequests/NewServiceRequest'));
const ViewServiceRequest = lazy(() => import('./components/Activation/ServiceRequests/ViewServiceRequest'));
const ClientProfileLanding = lazy(() => import('./components/Activation/ClientProfile/ClientProfileLanding'));
const SoldSlotConfiguration = lazy(() => import('./components/Activation/SoldSlot/SoldSlotConfiguration'));
const SoldSlotAvailability = lazy(() => import('./components/Activation/SoldSlot/SoldSlotAvailability'));
const BookMetaData = lazy(() => import('./components/Automation/BookMetaData'));
const RestrictTerritory = lazy(() => import('./components/RestrictTerritory/RestrictTerritory'));
const MonthlyReport = lazy(() => import('./components/Activation/MonthlyReport'));
const UploadAudioContract = lazy(() => import('./components/AudioContract/UploadAudioContract'));
const ViewAudioContract = lazy(() => import('./components/AudioContract/ViewAudioContract'));
const NominationFilterTracking = lazy(() => import('./components/Nomination/NominationFilterTracking'));
const DashboardLayout = lazy(() => import('./components/Header/dashboard/layout'));
const AggregatedHistoricalDataLog = lazy(() => import('./components/PriorityReport/AggregatedHistoricalDataLog'));
const DownPriceCampaignHelpPage = lazy(() =>
  import('./components/DownPriceCampaignHelpPage/DownPriceCampaignHelpPage')
);
const TrafficLight = lazy(() => import('./components/TrafficLightReport/TrafficLightReport'));
const CostConfiguration = lazy(() => import('./components/Bookbub/CostConfiguration'));
const PageCountConfiguration = lazy(() => import('./components/Bookbub/PageCountConfiguration'));
const PriorityConfiguration = lazy(() => import('./components/Bookbub/PriorityConfiguration'));

// import Discovery from './components/Discovery/Discovery';
const ViewSharedDraft = lazy(() => import('./components/SharedDraft/ViewSharedDraft'));

const MasterPriceGrid = lazy(() => import('./components/MasterPriceGrid/MasterPriceGrid'));
const DlpUpdate = lazy(() => import('./components/DLPUpdate/DlpUpdate'));
const PriceLogViewer = lazy(() => import('./components/DLPUpdate/PriceLogViewer'));
const CampaignTypeGroup = lazy(() => import('./components/CampaignTypeGroup/CampaignTypeGroup'));

const RequireAuth = () => {
  const { token } = useContext(UserContext);
  if (!token) {
    return <Navigate to={`/login?redirect=${window.location.pathname}${window.location.search}`} />;
  }
  return (
    <>
      <DashboardLayout>
        <Suspense fallback={<RenderSpinner />}>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    </>
  );
};

const AppRouter = () => (
  <Routes>
    <Route path='/login' element={<Login />} />
    <Route exact path='/reset-password' element={<ResetPassword />} />
    <Route path='/' element={<RequireAuth />}>
      <Route exact path='/' element={<HomePage />} />
      <Route
        exact
        path='/widgets'
        element={<PrivateRoute component={CampaignWidgetHome} permission={ROUTES.widgets} />}
      />
      <Route path='/campaign' element={<PrivateRoute component={CampaignHome} permission={ROUTES.filterCampaigns} />} />
      <Route
        exact
        path='/campaign/create'
        element={<PrivateRoute component={Campaign} permission={ROUTES.newCampaign} />}
      />
      <Route
        exact
        path='/campaign/edit/:CampaignId'
        element={<PrivateRoute component={Campaign} permission={ROUTES.newCampaign} />}
      />
      <Route
        exact
        path='/campaign/clone/:CampaignId'
        element={<PrivateRoute component={Campaign} permission={ROUTES.newCampaign} />}
      />
      <Route
        exact
        path='/campaign/facebook/:CampaignId'
        element={<PrivateRoute component={FacebookAdManager} permission={ROUTES.newCampaign} />}
      />
      <Route
        exact
        path='/campaign/facebook/:CampaignId/:FacebookId'
        element={<PrivateRoute component={FacebookAdManager} permission={ROUTES.newCampaign} />}
      />
      <Route
        exact
        path='/configuration/campaign/type'
        element={<PrivateRoute component={CampaignType} permission={ROUTES.campaignType} />}
      />
      <Route
        exact
        path='/configuration'
        element={<PrivateRoute component={CCMapping} permission={ROUTES.customCategoryMapping} />}
      />
      <Route
        exact
        path='/configuration/campaign/type/edit/:id'
        element={<PrivateRoute component={CampaignTypeEdit} permission={ROUTES.campaignType} />}
      />
      <Route
        exact
        path='/campaign/nomination'
        element={<PrivateRoute component={NominationHome} permission={ROUTES.nomination} />}
      />
      <Route exact path='/book/:primaryIsbn' element={<BookDetail />} />
      <Route
        exact
        path='/configuration/books/edit'
        element={<PrivateRoute component={BookEdit} permission={ROUTES.editBook} />}
      />
      <Route
        exact
        path='/configuration/blocklist'
        element={<PrivateRoute component={Blocklist} permission={ROUTES.blocklist} />}
      />
      <Route
        exact
        path='/configuration/restrict-territory'
        element={<PrivateRoute component={RestrictTerritory} permission={ROUTES.restrictTerritory} />}
      />
      <Route
        exact
        path='/configuration/campaign-type-group'
        element={<PrivateRoute component={CampaignTypeGroup} permission={ROUTES.campaignTypeGroup} />}
      />
      <Route
        exact
        path='/reports'
        element={<PrivateRoute component={TSDReport} permission={ROUTES.opportunityTitleReport} />}
      />
      <Route
        exact
        path='/reports/priority-report'
        element={<PrivateRoute component={PriorityReport} permission={ROUTES.priorityTitleReport} />}
      />
      <Route
        exact
        path='/reports/priority-report/batchdetail'
        element={<PrivateRoute component={BatchLevelPriorityData} permission={ROUTES.priorityTitleReport} />}
      />

      <Route
        exact
        path='/others/utilities'
        element={<PrivateRoute component={FirebrandReports} permission={ROUTES.firebrandReports} />}
      />
      <Route
        exact
        path='/others/utilities/nomination-filter-tracking'
        element={<PrivateRoute component={NominationFilterTracking} permission={ROUTES.nominationFilterTracking} />}
      />
      <Route
        exact
        path='/others/utilities/campaign-help'
        element={<PrivateRoute component={CampaignHelpPage} permission={ROUTES.campaignHelp} />}
      />
      <Route
        exact
        path='/others/utilities/downprice-campaign-help'
        element={<PrivateRoute component={DownPriceCampaignHelpPage} permission={ROUTES.DownPriceCampaignHelpPage} />}
      />
      <Route
        exact
        path='/others/utilities/aggregated-historical-data-log'
        element={
          <PrivateRoute component={AggregatedHistoricalDataLog} permission={ROUTES.aggregatedHistoricalDataLog} />
        }
      />
      <Route
        exact
        path='/others/audio-contract'
        element={<PrivateRoute component={UploadAudioContract} permission={ROUTES.uploadAudioContract} />}
      />
      <Route
        exact
        path='/others/audio-contract/view'
        element={<PrivateRoute component={ViewAudioContract} permission={ROUTES.viewAudioContract} />}
      />
      <Route
        exact
        path='/others/upload'
        element={<PrivateRoute component={ExternalTitleUpload} permission={ROUTES.uploadPartnerTitle} />}
      />
      <Route
        exact
        path='/others/upload/upload-events'
        element={<PrivateRoute component={UploadMileStoneEvents} permission={ROUTES.upload} />}
      />
      <Route
        exact
        path='/others/social-data'
        element={<PrivateRoute component={SocialData} permission={ROUTES.authorSocialData} />}
      />
      <Route
        exact
        path='/others'
        element={<PrivateRoute component={MileStoneHome} permission={ROUTES.milestoneCalendar} />}
      />
      <Route
        exact
        path='/others/orion-scheduler'
        element={<PrivateRoute component={OrionSchedular} permission={ROUTES.orionScheduler} />}
      />
      <Route
        exact
        path='/others/utilities/traffic-light-report'
        element={<PrivateRoute component={TrafficLight} permission={ROUTES.trafficLightReport} />}
      />

      <Route exact path='/event' element={<AddEvent />} />
      <Route exact path='/users' element={<PrivateRoute component={Users} permission={ROUTES.userManagement} />} />
      <Route exact path='/user-role' element={<PrivateRoute component={UserRole} permission={ROUTES.userRole} />} />
      <Route
        exact
        path='/configuration/external-submission'
        element={<PrivateRoute component={Submission} permission={ROUTES.externalSubmission} />}
      />
      <Route
        exact
        path='/configuration/dynamic-promo-price'
        element={<PrivateRoute component={PromoPrice} permission={ROUTES.draftPromoPrice} />}
      />
      <Route exact path='/configuration/validate-title' element={<ValidateTitle />} />

      {/* Bookbub routes */}
      <Route
        exact
        path='/configuration/bookbub/cost-config'
        element={<PrivateRoute component={CostConfiguration} permission={ROUTES.costConfiguration} />}
      />
      <Route
        exact
        path='/configuration/bookbub/page-count-config'
        element={<PrivateRoute component={PageCountConfiguration} permission={ROUTES.pageCountConfiguration} />}
      />
      <Route
        exact
        path='/configuration/bookbub/priority-config'
        element={<PrivateRoute component={PriorityConfiguration} permission={ROUTES.priorityConfiguration} />}
      />

      <Route path='/recommendation-engine'>
        <Route path='' element={<PrivateRoute component={HaloRecommendation} permission={ROUTES.haloCampaign} />} />
        <Route
          path='result/blast'
          element={<PrivateRoute component={BlastRecommendationEngine} permission={ROUTES.blastRecommendation} />}
        />
        <Route
          path='result/blast/:blastID'
          element={<PrivateRoute component={BlastRecommendationEngine} permission={ROUTES.blastRecommendation} />}
        />
        <Route
          path='rules/blast/configuration'
          element={<PrivateRoute component={BlastRuleConfiguration} permission={ROUTES.blastConfiguration} />}
        />
        <Route
          path='rules/content-deals/configuration'
          element={<PrivateRoute component={ContentDealsConfig} permission={ROUTES.blastRecommendation} />}
        />
        <Route
          path='result/content-deals'
          element={
            <PrivateRoute
              component={ContentNLDealsRecommendationResult}
              permission={ROUTES.contentNldealsRecommendation}
            />
          }
        />
        <Route
          path='result/content-deals/:blastId'
          element={
            <PrivateRoute component={ContentNLDealsRecommendationResult} permission={ROUTES.blastRecommendation} />
          }
        />
        <Route
          path='rules/lending-deals/configuration'
          element={<PrivateRoute component={LendingDealsConfiguration} permission={ROUTES.lendingDealsConfiguration} />}
        />
        <Route
          path='result/lending-deals'
          element={
            <PrivateRoute component={LendingRecommendationResult} permission={ROUTES.lendingDealsRecommendation} />
          }
        />
        <Route
          path='result/lending-deals/:blastId'
          element={
            <PrivateRoute component={LendingRecommendationResult} permission={ROUTES.lendingDealsConfiguration} />
          }
        />
        {/* This below route is not used any where in project  */}
        {/* <Route path='blast/bookbub/:blastID' element={<BookbubRecommendationResult />} /> */}
      </Route>

      {/* <Route exact path="/recommenationengine/rules/configuration/bookbub" element={<BookbubRuleConfiguration/>} />  
        <Route exact path="/recommenationengine/result/bookbub" element={<BookbubRecommendationResult/>} />   */}

      <Route
        exact
        path='/configuration/generate-retailer-links'
        element={<PrivateRoute component={GenerateRetailerLinks} permission={ROUTES.generateRetailerLinks} />}
      />

      <Route
        exact
        path='/service-request'
        element={<PrivateRoute component={NewServiceRequest} permission={ROUTES.addNewServiceRequest} />}
      />
      <Route
        exact
        path='/service-request/view'
        element={<PrivateRoute component={ViewServiceRequest} permission={ROUTES.viewServiceRequest} />}
      />
      <Route
        exact
        path='/service-request/clients'
        element={<PrivateRoute component={ClientProfileLanding} permission={ROUTES.clientProfile} />}
      />
      <Route
        exact
        path='/service-request/sold-slot-configuration'
        element={<PrivateRoute component={SoldSlotConfiguration} permission={ROUTES.soldSlotConfiguration} />}
      />
      <Route
        exact
        path='/service-request/sold-slot-availability'
        element={<PrivateRoute component={SoldSlotAvailability} permission={ROUTES.soldSlotAvailability} />}
      />
      <Route
        exact
        path='/service-request/monthly-report'
        element={<PrivateRoute component={MonthlyReport} permission={ROUTES.monthlyReport} />}
      />
      <Route exact path='/metadata' element={<BookMetaData />} />

      <Route
        exact
        path='/trigger-titles'
        element={<PrivateRoute component={TriggerTitleConfiguration} permission={ROUTES.triggerTitles} />}
      />
      <Route
        exact
        path='/trigger-titles/view'
        element={<PrivateRoute component={ViewTriggerConfiguration} permission={ROUTES.viewExistingConfiguration} />}
      />
      <Route
        exact
        path='/trigger-titles/result'
        element={<PrivateRoute component={TriggerTitlesResult} permission={ROUTES.viewTriggerResult} />}
      />
      <Route
        exact
        path='/trigger-titles/:id'
        element={<PrivateRoute component={TriggerTitleConfiguration} permission={ROUTES.viewExistingConfiguration} />}
      />

      <Route
        exact
        path='/shared/draft'
        element={<PrivateRoute component={ViewSharedDraft} permission={ROUTES.sharedDraft} />}
      />

      <Route
        exact
        path='/master-price-grid'
        element={<PrivateRoute component={MasterPriceGrid} permission={ROUTES.uploadMasterPriceGrid} />}
      />
      <Route
        exact
        path='/configuration/dlp'
        element={<PrivateRoute component={DlpUpdate} permission={ROUTES.dlpUpdate} />}
      />
      <Route
        exact
        path='/pricelog-viewer'
        element={<PrivateRoute component={PriceLogViewer} permission={ROUTES.PriceLogViewer} />}
      />
      <Route path='/access-denied' element={<AccessDenied />} />
    </Route>
    <Route path='*' element={PageNotFound} />
  </Routes>
);

export default AppRouter;
