import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { DataGridPro, getGridStringOperators } from '@mui/x-data-grid-pro';
import NoDataFound from './NoDataFound';
import CustomToolbar from './CustomToolbar';
import { style } from './ormDataGridStyle';
import { doesNotContainOperator } from './CustomFilter';

const ORMDataGridV2 = forwardRef(
  (
    {
      rows,
      columns,
      pinnedColumns,
      //pinnedColumns should be in following format
      // {
      //   left?: string[]; // Optional field names to pin to the left
      //   right?: string[]; // Optional field names to pin to the right
      // }
      columnVisibilityModel, // provide object of hidden column
      uniqueRowId = '_id',
      onRowSelectionChange,
      onRowClick,
      checkboxSelection = false,
      handleSortModelChange,
      disableMultipleRowSelection = false,
      defaultPagination = { pageSize: 50, page: 0 },
      pageSizeOptions = [50, 100, 150],
      height = '800px',
      variant,
      defaultRowSelectionModel = [],
      children,
      toolbarOptions = ['COLUMNS', 'FILTER', 'DENSITY', 'EXPORT', 'SEARCH'],
      isDefaultCompact = false,
      fileName,
      ...rest
    },
    reference
  ) => {
    const [rowSelectionModel, setRowSelectionModel] = useState(defaultRowSelectionModel);
    const [paginationModel, setPaginationModel] = React.useState(defaultPagination);
    const [isCompact, setIsCompact] = useState(isDefaultCompact);

    const handleRowSelectionChange = newRowSelectionModel => {
      setRowSelectionModel(newRowSelectionModel);
      if (onRowSelectionChange) {
        const selectedRows = newRowSelectionModel.map(selectedId => rows.find(row => row[uniqueRowId] === selectedId));

        onRowSelectionChange(selectedRows);
      }
    };

    useImperativeHandle(reference, () => ({
      removeRowSelectionModel() {
        setRowSelectionModel([]);
      },
    }));

    const handleOnRowClick = e => {
      if (onRowClick) onRowClick(e);
    };

    const onSortModelChange = data => {
      if (handleSortModelChange) {
        handleSortModelChange(data);
      }
    };

    const cellClassWithPointer = 'cell-pointer';
    //If data is not found then use 300px height to show no data found icon
    const tableHeight = rows?.length > 0 ? height : '350px';

    const handleMenuClick = toggle => {
      setIsCompact(toggle);
    };

    const defaultStringOperators = getGridStringOperators();
    // Add custom filter does not contain to string columns
    const updatedColumns = columns.map(column => {
      // If there is no type defined then by default we considering string type
      if (!column.type || column.type === 'string') {
        return {
          ...column,
          filterOperators: [...defaultStringOperators, doesNotContainOperator],
        };
      }
      return column;
    });

    return (
      <div style={{ height: tableHeight, width: '100%', overflowX: 'auto' }}>
        <DataGridPro
          rows={rows}
          columns={updatedColumns}
          getRowId={row => row[uniqueRowId]}
          checkboxSelection={checkboxSelection}
          disableRowSelectionOnClick
          onRowSelectionModelChange={handleRowSelectionChange}
          slots={{ toolbar: toolbarOptions?.length > 0 ? CustomToolbar : null, noRowsOverlay: NoDataFound }}
          slotProps={{
            toolbar: {
              children: children,
              printOptions: { disableToolbarButton: true },
              toolbarOptions: toolbarOptions,
              handleMenuClick: handleMenuClick,
              csvOptions: { allColumns: true, fileName: fileName, escapeFormulas: false },
            },
          }}
          autoHeight={true}
          rowSelectionModel={rowSelectionModel}
          onRowClick={handleOnRowClick}
          cellClassName={params => (params.field === 'name' ? cellClassWithPointer : '')}
          MuiDataGrid-row
          getRowHeight={() => (isCompact ? 32 : 'auto')}
          sx={style(variant)}
          onSortModelChange={onSortModelChange}
          disableMultipleRowSelection={disableMultipleRowSelection}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={pageSizeOptions}
          initialState={{ pinnedColumns: pinnedColumns, columns: { columnVisibilityModel } }}
          {...rest}
        />
      </div>
    );
  }
);

export default ORMDataGridV2;
