import React from 'react';
import AppRouter from './AppRouter';
import './scss/styles.scss';
import 'react-dropzone-uploader/dist/styles.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LicenseInfo } from '@mui/x-license-pro';

import { UserContextProvider } from './UserContextProvider';
import ThemeProvider from './theme';
import { SettingsDrawer, SettingsProvider } from './components/settings';
import { WebSocketProvider } from './WebSocketProvider';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

const toastConfig = {
  position: 'top-right',
  autoClose: 2000,
  newestOnTop: true,
};

export default function App() {
  return (
    <WebSocketProvider>
    <UserContextProvider>
      <ThemeProvider>
        <SettingsProvider
          defaultSettings={{
            themeLayout: 'horizontal', // 'vertical' | 'horizontal' | 'mini'
          }}
        >
          <SettingsDrawer />
          {/* <WebSocketProvider> */}
          <AppRouter />
          {/* </WebSocketProvider> */}
        </SettingsProvider>
        <ToastContainer {...toastConfig} />
      </ThemeProvider>
    </UserContextProvider>
    </WebSocketProvider>
  );
}
