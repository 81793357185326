import React from 'react';
import { Box, CircularProgress } from '@mui/material';

/* 
  @description : Components to display spinner
  @args
    @message    : Message to be displayed during spinner
    @isOverlay  : Display spinner as overlay, boolean
*/
const RenderSpinner = ({ message, isOverlay = true, isOverlaySpinner = true }) => (
  <Box className={isOverlay ? 'overlay' : ''}>
    <Box className={isOverlaySpinner ? 'overlay-spinner' : ''} sx={{ textAlign: 'center' }}>
      <CircularProgress />
      <hr />
      <span className={isOverlay ? 'text-white' : ''}>{message || 'Loading...'}</span>
    </Box>
  </Box>
);

export default RenderSpinner;
