import React, { useContext, lazy, Suspense } from 'react';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { UserContext } from './UserContextProvider';
import RenderSpinner from './components/Spinner';
import TriggerTitlesResult from './components/TriggerTitles/TriggerTitlesResult/TriggerTitlesResult';

const TriggerTitleConfiguration = lazy(() => import('./components/TriggerTitles/TriggerTitleConfiguration'));
const ViewTriggerConfiguration = lazy(() => import('./components/TriggerTitles/ViewTriggerConfiguration'));
const BatchLevelPriorityData = lazy(() => import('./components/PriorityReport/BatchLevelPriorityData'));
const Login = lazy(() => import('./components/Login/Login'));
const HomePage = lazy(() => import('./components/Homepage/Homepage'));
const CampaignHome = lazy(() => import('./components/CampaignHome/CampaignHome'));
const Campaign = lazy(() => import('./components/Campaign/Campaign'));
const CampaignType = lazy(() => import('./components/Campaign/CampaignType'));
const CampaignTypeEdit = lazy(() => import('./components/Campaign/CampaignTypeEdit'));
const NominationHome = lazy(() => import('./components/Nomination/NominationHome'));
const BookEdit = lazy(() => import('./components/BookEdit/BookEdit'));
const BookDetail = lazy(() => import('./components/BookDetail/BookDetail'));
const Blocklist = lazy(() => import('./components/Blocklist/Blocklist'));
const FirebrandReports = lazy(() => import('./components/FirebrandReports/FirebrandReports'));
const SocialData = lazy(() => import('./components/SocialData/SocialData'));
const PageNotFound = lazy(() => import('./components/PageNotFound/PageNotFound'));
const FacebookAdManager = lazy(() => import('./components/Campaign/Facebook/FacebookAdManager.js'));
const ExternalTitleUpload = lazy(() => import('./components/ExternalTitle/Upload'));
const TSDReport = lazy(() => import('./components/TSDReport/TSDReport'));
const PriorityReport = lazy(() => import('./components/PriorityReport/PriorityReport'));
const MileStoneHome = lazy(() => import('./components/Milestone/MilestoneHome'));
const AddEvent = lazy(() => import('./components/Calendar/AddEditEvent'));
const UploadMileStoneEvents = lazy(() => import('./components/Milestone/UploadMilestoneEvents'));
const OrionSchedular = lazy(() => import('./components/OrionSchedular/OrionSchedular'));
const CampaignWidgetHome = lazy(() => import('./components/Widgets/CampaignWidgetHome'));
const Users = lazy(() => import('./components/Users/Users'));
const ResetPassword = lazy(() => import('./components/Login/reset-password'));
const Submission = lazy(() => import('./components/ExternalSubmission/Submission'));
const ValidateTitle = lazy(() => import('./components/ValidateTitleList/Validatelist'));
const BlastRuleConfiguration = lazy(() => import('./components/RecommendationConfiguration/BlastRuleConfiguration'));
const BlastRecommendationEngine = lazy(() => import('./components/RecommendationResult/BlastRecommendationEngine'));
const HaloRecommendation = lazy(() => import('./components/RecommendationHalo/HaloRecommendation'));
const GenerateRetailerLinks = lazy(() => import('./components/RetailerLinks/GenerateRetailerLinks'));
const ContentDealsConfig = lazy(() => import('./components/RecommendationConfiguration/ContentNLDealsConfiguration'));
const ContentNLDealsRecommendationResult = lazy(() =>
  import('./components/RecommendationResult/ContentNLDealsRecommendationResult')
);
const LendingDealsConfiguration = lazy(() =>
  import('./components/RecommendationConfiguration/LendingDealsConfiguration')
);
const LendingRecommendationResult = lazy(() => import('./components/RecommendationResult/LendingRecommendationResult'));

const CampaignHelpPage = lazy(() => import('./components/CampaignHelp/CampaignHelpPage'));
const PromoPrice = lazy(() => import('./components/DynamicPromoPrice/Promoprice'));
const CCMapping = lazy(() => import('./components/CustomCategory/CCMapping'));
const NewServiceRequest = lazy(() => import('./components/Activation/ServiceRequests/NewServiceRequest'));
const ViewServiceRequest = lazy(() => import('./components/Activation/ServiceRequests/ViewServiceRequest'));
const ClientProfileLanding = lazy(() => import('./components/Activation/ClientProfile/ClientProfileLanding'));
const SoldSlotConfiguration = lazy(() => import('./components/Activation/SoldSlot/SoldSlotConfiguration'));
const SoldSlotAvailability = lazy(() => import('./components/Activation/SoldSlot/SoldSlotAvailability'));
const BookMetaData = lazy(() => import('./components/Automation/BookMetaData'));
const RestrictTerritory = lazy(() => import('./components/RestrictTerritory/RestrictTerritory'));
const MonthlyReport = lazy(() => import('./components/Activation/MonthlyReport'));
const UploadAudioContract = lazy(() => import('./components/AudioContract/UploadAudioContract'));
const ViewAudioContract = lazy(() => import('./components/AudioContract/ViewAudioContract'));
const NominationFilterTracking = lazy(() => import('./components/Nomination/NominationFilterTracking'));
const DashboardLayout = lazy(() => import('./components/Header/dashboard/layout'));
const AggregatedHistoricalDataLog = lazy(() => import('./components/PriorityReport/AggregatedHistoricalDataLog'));
const DownPriceCampaignHelpPage = lazy(() =>
  import('./components/DownPriceCampaignHelpPage/DownPriceCampaignHelpPage')
);
const TrafficLight = lazy(() => import('./components/TrafficLightReport/TrafficLightReport'));
const CostConfiguration = lazy(() => import('./components/Bookbub/CostConfiguration'));
const PageCountConfiguration = lazy(() => import('./components/Bookbub/PageCountConfiguration'));
const PriorityConfiguration = lazy(() => import('./components/Bookbub/PriorityConfiguration'));

// import Discovery from './components/Discovery/Discovery';
const ViewSharedDraft = lazy(() => import('./components/SharedDraft/ViewSharedDraft'));

const MasterPriceGrid = lazy(() => import('./components/MasterPriceGrid/MasterPriceGrid'));
const DlpUpdate = lazy(() => import('./components/DLPUpdate/DlpUpdate'));
const PriceLogViewer = lazy(() => import('./components/DLPUpdate/PriceLogViewer'));
const CampaignTypeGroup = lazy(() => import('./components/CampaignTypeGroup/CampaignTypeGroup'));

const RequireAuth = ({ children }) => {
  const { token } = useContext(UserContext);
  if (!token) {
    return <Navigate to={`/login?redirect=${window.location.pathname}${window.location.search}`} />;
  }
  return (
    <>
      <DashboardLayout>
        <Suspense fallback={<RenderSpinner />}>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    </>
  );
};

const AppRouter = () => (
  <Routes>
    <Route path='/login' element={<Login />} />
    <Route exact path='/reset-password' element={<ResetPassword />} />
    <Route path='/' element={<RequireAuth />}>
      <Route exact path='/' element={<HomePage />} />
      <Route exact path='/widgets' element={<CampaignWidgetHome />} />
      <Route path='/campaign' element={<CampaignHome />} />
      <Route exact path='/campaign/create' element={<Campaign />} />
      <Route exact path='/campaign/edit/:CampaignId' element={<Campaign />} />
      <Route exact path='/campaign/clone/:CampaignId' element={<Campaign />} />
      <Route exact path='/campaign/facebook/:CampaignId' element={<FacebookAdManager />} />
      <Route exact path='/campaign/facebook/:CampaignId/:FacebookId' element={<FacebookAdManager />} />
      <Route exact path='/configuration/campaign/type' element={<CampaignType />} />
      <Route exact path='/configuration' element={<CCMapping />} />
      <Route exact path='/configuration/campaign/type/edit/:id' element={<CampaignTypeEdit />} />
      <Route exact path='/campaign/nomination' element={<NominationHome />} />
      <Route exact path='/book/:primaryIsbn' element={<BookDetail />} />
      <Route exact path='/configuration/books/edit' element={<BookEdit />} />
      <Route exact path='/configuration/blocklist' element={<Blocklist />} />
      <Route exact path='/configuration/restrict-territory' element={<RestrictTerritory />} />
      <Route exact path='/configuration/campaign-type-group' element={<CampaignTypeGroup />} />
      <Route exact path='/reports' element={<TSDReport />} />
      <Route exact path='/reports/priority-report' element={<PriorityReport />} />
      <Route exact path='/reports/priority-report/batchdetail' element={<BatchLevelPriorityData />} />

      <Route exact path='/others/utilities' element={<FirebrandReports />} />
      <Route exact path='/others/utilities/nomination-filter-tracking' element={<NominationFilterTracking />} />
      <Route exact path='/others/utilities/campaign-help' element={<CampaignHelpPage />} />
      <Route exact path='/others/utilities/downprice-campaign-help' element={<DownPriceCampaignHelpPage />} />
      <Route exact path='/others/utilities/aggregated-historical-data-log' element={<AggregatedHistoricalDataLog />} />
      <Route exact path='/others/audio-contract' element={<UploadAudioContract />} />
      <Route exact path='/others/audio-contract/view' element={<ViewAudioContract />} />
      <Route exact path='/others/upload' element={<ExternalTitleUpload />} />
      <Route exact path='/others/upload/upload-events' element={<UploadMileStoneEvents />} />
      <Route exact path='/others/social-data' element={<SocialData />} />
      <Route exact path='/others' element={<MileStoneHome />} />
      <Route exact path='/others/orion-scheduler' element={<OrionSchedular />} />
      <Route exact path='/others/utilities/traffic-light-report' element={<TrafficLight />} />

      <Route exact path='/event' element={<AddEvent />} />
      <Route exact path='/users' element={<Users />} />
      <Route exact path='/configuration/external-submission' element={<Submission />} />
      <Route exact path='/configuration/dynamic-promo-price' element={<PromoPrice />} />
      <Route exact path='/configuration/validate-title' element={<ValidateTitle />} />

      {/* Bookbub routes */}
      <Route exact path='/configuration/bookbub/cost-config' element={<CostConfiguration />} />
      <Route exact path='/configuration/bookbub/page-count-config' element={<PageCountConfiguration />} />
      <Route exact path='/configuration/bookbub/priority-config' element={<PriorityConfiguration />} />

      <Route path='/recommendation-engine'>
        <Route path='' element={<HaloRecommendation />} />
        <Route path='result/blast' element={<BlastRecommendationEngine />} />
        <Route path='result/blast/:blastID' element={<BlastRecommendationEngine />} />
        <Route path='rules/blast/configuration' element={<BlastRuleConfiguration />} />
        <Route path='rules/content-deals/configuration' element={<ContentDealsConfig />} />
        <Route path='result/content-deals' element={<ContentNLDealsRecommendationResult />} />
        <Route path='result/content-deals/:blastId' element={<ContentNLDealsRecommendationResult />} />
        <Route path='rules/lending-deals/configuration' element={<LendingDealsConfiguration />} />
        <Route path='result/lending-deals' element={<LendingRecommendationResult />} />
        <Route path='result/lending-deals/:blastId' element={<LendingRecommendationResult />} />
        {/* This below route is not used any where in project  */}
        {/* <Route path='blast/bookbub/:blastID' element={<BookbubRecommendationResult />} /> */}
      </Route>

      {/* <Route exact path="/recommenationengine/rules/configuration/bookbub" element={<BookbubRuleConfiguration/>} />  
        <Route exact path="/recommenationengine/result/bookbub" element={<BookbubRecommendationResult/>} />   */}

      <Route exact path='/configuration/generate-retailer-links' element={<GenerateRetailerLinks />} />

      <Route exact path='/service-request' element={<NewServiceRequest />} />
      <Route exact path='/service-request/view' element={<ViewServiceRequest />} />
      <Route exact path='/service-request/clients' element={<ClientProfileLanding />} />
      <Route exact path='/service-request/sold-slot-configuration' element={<SoldSlotConfiguration />} />
      <Route exact path='/service-request/sold-slot-availability' element={<SoldSlotAvailability />} />
      <Route exact path='/service-request/monthly-report' element={<MonthlyReport />} />
      <Route exact path='/metadata' element={<BookMetaData />} />

      <Route exact path='/trigger-titles' element={<TriggerTitleConfiguration />} />
      <Route exact path='/trigger-titles/view' element={<ViewTriggerConfiguration />} />
      <Route exact path='/trigger-titles/result' element={<TriggerTitlesResult />} />
      <Route exact path='/trigger-titles/:id' element={<TriggerTitleConfiguration />} />

      <Route exact path='/shared/draft' element={<ViewSharedDraft />} />
      <Route exact path='/master-price-grid' element={<MasterPriceGrid />} />
      <Route exact path='/configuration/dlp' element={<DlpUpdate />} />
      <Route exact path='/pricelog-viewer' element={<PriceLogViewer />} />
    </Route>
    <Route path='*' element={PageNotFound} />
  </Routes>
);

export default AppRouter;
