import dayjs from 'dayjs';
import { format } from 'date-fns';

//dayjs plugins
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isBetween from 'dayjs/plugin/isBetween';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { VALID_DATE_FORMATS } from 'src/constants/const';

//Registering dayjs plugins
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(customParseFormat);

/**
 * Returns an instance of dayjs.
 * @param {...any} args - Arguments to be passed to the dayjs object.
 * @returns {Object} - Instance of dayjs.
 */
//We can pass any arguments to it that we would pass to dayjs object.
//We can also chain any functions to this. See dayjs documentation - https://day.js.org/docs/en/installation/installation
export const FormatDateTime = (...args) => {
  return dayjs(...args);
};

//This function returns a Day.js object in UTC for the specified date and time.
export const FormatDateTimeUTC = (...args) => {
  return dayjs.utc(...args);
};

export const FormatDateTimeInESTTimeZone = (date, reqformat = 'MM/DD/YYYY HH:mm') => {
  // Convert the UTC date to the Eastern Standard Time (EST) time zone
  const estDate = dayjs(date).utc().tz('America/New_York'); // 'America/New_York' is the time zone for EST
  // Format the EST date as needed
  return estDate.format('MM/DD/YYYY h:mm A [EST]');
};
/**
 * Determines if Daylight Saving Time is active or not.
 * @returns {boolean} - True if DST is active, false otherwise.
 */
export const isDST = () => {
  const today = new Date();
  const jan = new Date(today.getFullYear(), 0, 1);
  const jul = new Date(today.getFullYear(), 6, 1);
  const stdTimezoneOffset = Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());

  return new Date().getTimezoneOffset < stdTimezoneOffset;
};

/**
 * Custom date sorting function.
 * @param {string} currentDate - Current date to compare.
 * @param {string} futureDate - Future date to compare.
 * @param {string} order - Sorting order ("asc" or "desc").
 * @returns {number} - Difference between the dates.
 */
export const _dateSort = (currentDate, futureDate, order) => {
  /**
   *   date sort for react bootstrap table 2.
   * We found in our investigation that date sorting is not functioning correctly for nomination.
   * currently this is used by FB clone ability and nomination
   * this fix is part of the FB clone feedback(https://openroadmedia.atlassian.net/browse/ORMTECH-1423)
   */
  if (currentDate && futureDate && order) {
    if (order === 'asc') {
      return Date.parse(currentDate) - Date.parse(futureDate);
    } else if (order === 'desc') {
      return Date.parse(futureDate) - Date.parse(currentDate);
    }
  }
};

/**
 Description : Format date in specified format. Mongo date are stored in UTC. When we use format from date-fns, it will automatically convert dates into local timezone (which in out case is EST). So UTC string like 2021-02-05T00:00:00.000Z can be converted to Feb 1, 2021. To avoid this, we offset the incoming date with current timezone and than apply format
 * @param {string} dateString - Date string to format.
 * @param {string} dateFormat - Desired output format.
 * @returns {string|null} - Formatted date string or null if input is empty.
 */
export const _formatDate = (dateString, dateformat) => {
  if (!dateString) return null;
  // console.log('date string',dateString);
  const dt = new Date(dateString);
  // Date are in UTC but date-fns format will always convert it to local timezone. So to avoid this, doing offset with local timezone
  try {
    const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);
    // console.log('dt date', dtDateOnly);
    let newDate = format(dtDateOnly, dateformat);
    return newDate;
  } catch (err) {
    // console.log(err);
    // console.log('DT', dt);
    throw err;
  }
};

/**
 * Formats a date string in the specified format.
 * @param {string} date - The date string to format.
 * @param {string} DateFormat - Desired output format.
 * @param {number} SubtractDay - Number of days to subtract (optional).
 * @returns {string|null} - Formatted date string or null if input is empty.
 */
export const FormatDateString = (date, DateFormat = 'YYYY-MM-DD', SubtractDay = 0) => {
  if (!date) return '';
  const dateobj = new Date(date);
  if (SubtractDay > 0) dateobj.setDate(dateobj.getDate() - SubtractDay);

  let month = dateobj.getUTCMonth() + 1;
  if (month < 10) month = `0${month}`;

  let day = dateobj.getUTCDate();
  if (day < 10) day = `0${day}`;

  let year = dateobj.getUTCFullYear();
  switch (DateFormat) {
    case 'YYYY-MM-DD':
      return `${year}-${month}-${day}`;
    case 'YYYY/MM/DD':
      return `${year}/${month}/${day}`;
    case 'MM/DD/YYYY':
      return `${month}/${day}/${year}`;
    case 'MM-DD-YYYY':
      return `${month}-${day}-${year}`;
    case 'YYYYMMDD':
      return `${year}${month}${day}`;
    case 'MM-DD':
      return `${month}-${day}`;
    case 'MM/DD':
      return `${month}/${day}`;
    case 'MMDD':
      return `${month}${day}`;
    case 'YY':
      return year;
    case 'MM':
      return month;
    case 'DD':
      return day;
    default:
      return ``;
  }
};

/**
 * Formats a date string in the specified format, with the option to subtract a specified number of days.
 * This function is used in MileStone pages.
 * @param {string} date - The date string to format.
 * @param {string} DateFormat - Desired output format.
 * @param {number} SubtractDay - Number of days to subtract (optional).
 * @param {string} hours - Hours in the format 'HH:MM' (optional).
 * @returns {string|null} - Formatted date string or null if input is empty.
 */
export const FormatDateStringMileStone = (date, DateFormat = 'YYYY-MM-DD', SubtractDay = 0, hours = '10:10') => {
  if (!date) return '';
  const dateobj = new Date(date);
  if (SubtractDay > 0) dateobj.setDate(dateobj.getDate() - SubtractDay);

  let month = dateobj.getUTCMonth() + 1;
  if (month < 10) month = `0${month}`;

  let day = dateobj.getUTCDate();
  if (day < 10) day = `0${day}`;

  let year = dateobj.getUTCFullYear();
  switch (DateFormat) {
    case 'YYYY-MM-DD':
      return `${year}-${month}-${day}`;
    case 'YYYY/MM/DD':
      return `${year}/${month}/${day}`;
    case 'MM/DD/YYYY':
      return `${month}/${day}/${year}`;
    case 'MM-DD-YYYY':
      return `${month}-${day}-${year}`;
    case 'MM-DD':
      return `${month}-${day}`;
    case 'MM/DD':
      return `${month}/${day}`;
    case 'MMDD':
      return `${month}${day}`;
    case 'YY':
      return year;
    case 'MM':
      return month;
    case 'DD':
      return day;
    default:
      return ``;
  }
};

/**
  Calculates the broadcast year of an event based on the given date and day.
  @param {string} date - The date of the event.
  @param {number} day - The number of days to subtract from the event date.
  @returns {string|null} - The broadcast year or null if the input date is empty.
*/
export const CalculateBroadCastYearOfEvent = (date, day) => {
  if (!date) return '';
  let year = FormatDateStringMileStone(date, 'YY', day);
  return year;
};

/**
  Formats a date string from MM/DD/YYYY to YYYY-MM-DD format.
  This function will just change the format without using JS date Object or moment or date-fns
  @param {string} date - The date string to be formatted.
  @returns {string|null} - The formatted date string or null if the input is empty.
*/
export const FormatDateTable = date => {
  if (!date) return '';
  const splittedArr = date.split('/');
  let d, month, year;
  if (Number(splittedArr[0]) && Number(splittedArr[0]) <= 12) {
    month = Number(splittedArr[0]);
    if (month < 10) month = `0${month}`;
  } else {
    month = FormatDateString(date, 'MM');
  }
  if (Number(splittedArr[1]) && Number(splittedArr[1]) <= 31) {
    d = Number(splittedArr[1]);
    if (d < 10) d = `0${d}`;
  }
  if (splittedArr[2]) {
    year = FormatDateString(date, 'YY');
  } else {
    year = FormatDateString(new Date(), 'YY');
  }
  if (year && d && month) return `${year}-${month}-${d}`;
  return FormatDateTime(date);
};

/**
 * Checks if a date string is in a valid format.
 * @param {string} date - The date string to validate.
 * @param {string} dateFormat - Desired date format to validate against.
 * @returns {boolean} - True if the date string is valid, false otherwise.
 *
 * StrickCheck based on https://day.js.org/docs/en/parse/is-valid#docsNav
 */
export const isValidDateFormat = (date, dateFormat = 'MM/DD/YYYY', isStrictCheck) => {
  if (!date) return false;
  if (isStrictCheck) return FormatDateTime(date, dateFormat, true).isValid();
  else return FormatDateTime(date).isValid();
};

// This function check VALID_DATE_FORMATS
export const isOrmValidDateFormat = date => {
  if (!date) return false;
  let isValid = false;
  VALID_DATE_FORMATS.forEach(format => {
    if (isValidDateFormat(date, format, true)) {
      isValid = true;
    }
  });
  return isValid;
};

/**
 * Calculates the difference in days between two dates.
 * @param {string|Date} oldDate - The first date.
 * @param {string|Date} newDate - The second date.
 * @returns {number|false} - The difference in days or false if the dates are not valid.
 */
export const DiffDates = (oldDate, newDate) => {
  if (!oldDate || !newDate) return false;

  // Convert date strings to Date objects if they are not already objects
  if (typeof oldDate !== 'object') oldDate = new Date(oldDate);
  if (typeof newDate !== 'object') newDate = new Date(newDate);

  // Check if the converted dates are valid using FormatDateTime.isValid() function
  if (!FormatDateTime(oldDate).isValid() || !FormatDateTime(newDate).isValid()) return false;

  // Calculate the difference in days using FormatDateTime.diff() function
  return FormatDateTime(oldDate).diff(FormatDateTime(newDate), 'days');
};

/**
 * Compares two dates and returns the comparison result.
 * @param {string|Date} oldDate - The first date.
 * @param {string|Date} newDate - The second date.
 * @returns {number|false} - The comparison result: 0 if dates are equal, 1 if oldDate is greater, -1 if oldDate is less, or false if dates are not valid.
 */
export const CompareDates = (oldDate, newDate) => {
  if (!oldDate || !newDate) return false;

  // Convert date strings to Date objects
  oldDate = new Date(oldDate);
  newDate = new Date(newDate);

  // Check if the converted dates are valid using FormatDateTime.isValid() function
  if (!FormatDateTime(oldDate).isValid() || !FormatDateTime(newDate).isValid()) return false;

  // Calculate the difference in days using FormatDateTime.diff() function
  const number = FormatDateTime(oldDate).diff(FormatDateTime(newDate), 'days');

  // Return the comparison result
  if (number === 0) return 0;
  if (number < 0) return -1;
  else return 1;
};

/**
 * Gets the date one month before the current date.
 * @returns {string} - The date one month before the current date in the format "YYYY-MM-DD".
 */
export const getOneMonthBefore = () => {
  const dateobj = new Date();
  let year = dateobj.getFullYear();
  let month = dateobj.getUTCMonth();
  if (month === 0) {
    year = year - 1;
    month = 12;
  }
  if (month < 10) month = `0${month}`;
  let day = dateobj.getUTCDate();
  if (day < 10) day = `0${day}`;

  return `${year}-${month}-${day}`;
};

/* 
  Calculates the month and day from a given date.
  For example:
  06/22/2021 => 622
  01/01/2021 => 101
  @param {string} incomingDate - The input date.
  @param {number} day - The day to subtract from the input date.
  @returns {number|null} - The calculated month and day as a number or null if the input date is empty.
*/
export const CalculateMonthDayEvent = (incomingDate, day) => {
  if (incomingDate) {
    let formateddate = FormatDateString(incomingDate, 'YYYY-MM-DD');
    let startdatemonthday = FormatDateString(formateddate, 'MMDD', day);
    let finalnumber = Number(startdatemonthday);
    return finalnumber;
  }
};

/**
 * Calculates the total number of days in a given month.
 * @param {number} month - The month (1-12).
 * @param {number} year - The year.
 * @returns {number} - The total number of days in the month.
 */
export const CalculateTotalDaysInMonth = (month, year) => {
  let days = [31, year % 4 === 0 ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  return days[parseInt(month)];
};

/**
 * Gets the first or last date of a given month.
 * @param {string} date - The input date.
 * @param {string} day - The desired day ('first' or 'last').
 * @returns {string} - The first or last date of the month in the format "YYYY-MM-DD".
 */
export const GetMonthFirstLastDate = (date, day = 'first') => {
  let dateObj = new Date(date);
  let year = dateObj.getUTCFullYear();
  let month = dateObj.getUTCMonth() + 1;
  if (month < 10) month = `0${month}`;

  let finalDate = `${year}-${month}-01`;
  if (day !== 'first') {
    let lastDay = CalculateTotalDaysInMonth(month - 1, year);
    finalDate = `${year}-${month}-${lastDay}`;
  }
  return finalDate;
};

/**
 * Gets the current date in the format "YYYY/MM/DD".
 * @returns {string} - The current date in the format "YYYY/MM/DD".
 */
export const getCurrentDate = () => {
  let today = new Date();
  today.setHours(0, 0, 0, 0);
  today = FormatDateString(today, 'YYYY/MM/DD');
  return today;
};
