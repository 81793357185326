export const bools_types = new Set([
  'Bundle?',
  'Award Winner?',
  'Film/TV Adaptation?',
  'Search External Titles?',
  'NYT Bestseller?',
  'Author with Series',
  'Promoted Title Today?',
  'Show all Eligible Titles?',
]);

/* 
    To include title in nomination result where certain performance data is not available as per
    https://openroadmedia.atlassian.net/browse/ORMTECH-1688
*/
export const NATypes = new Set([
  'rank.currentRank',
  'rank.last90rank',
  'latestRating',
  'latestReviews',
  'consumerPrice.last7consumerPrice',
  'consumerPrice.last30consumerPrice',
  'consumerPrice.last90consumerPrice',
  'salesByRetailer.Amazon.last30Proceeds',
  'sales.last30Units',
  'sales.last7Units',
]);

//Filters.Field value
export const FiltersWithStringOperator = new Set(['publisher', 'bisacs']);
export const STRING_OPERATOR = [
  {
    Comparison_Operator: 'IN',
    label: 'In',
    value: '$in',
  },
  {
    Comparison_Operator: 'NOT IN',
    label: 'Not In',
    value: '$nin',
  },
];

export const COMP_OPERATOR = [
  {
    Comparison_Operator: 'GREATER_THAN_EQUAL',
    label: '>=',
    value: '$gte',
  },
  {
    Comparison_Operator: 'GREATER_THAN',
    label: '>',
    value: '$gt',
  },
  {
    Comparison_Operator: 'EQUAL',
    label: '==',
    value: '$eq',
  },
  {
    Comparison_Operator: 'LESS_THAN_EQUAL',
    label: '<=',
    value: '$lte',
  },
  {
    Comparison_Operator: 'LESS_THAN',
    label: '<',
    value: '$lt',
  },
];

export const COMP_OPERATOR_WITH_RANGE = [
  {
    Comparison_Operator: 'GREATER_THAN_EQUAL',
    label: '>=',
    value: '$gte',
  },
  {
    Comparison_Operator: 'GREATER_THAN',
    label: '>',
    value: '$gt',
  },
  {
    Comparison_Operator: 'EQUAL',
    label: '==',
    value: '$eq',
  },
  {
    Comparison_Operator: 'LESS_THAN_EQUAL',
    label: '<=',
    value: '$lte',
  },
  {
    Comparison_Operator: 'LESS_THAN',
    label: '<',
    value: '$lt',
  },
  {
    Comparison_Operator: 'RANGE',
    label: 'R',
    value: 'Range',
  },
];

export const BOOLEAN_SELECT = [
  {
    value: 'Yes',
    label: 'Yes',
  },
  {
    value: 'No',
    label: 'No',
  },
  // Made select clearable, so not required
  // {
  //   value: '',
  //   label: '',
  // },
];
